//import { PayPalButtons } from "@paypal/react-paypal-js";
import { useArticle } from "business/article/service/ArticleService";
import { getPlugginsFromIds, Pluggin } from "business/pluggin/PlugginService";
import RichTextComponent from "business/rich-content/RichTextComponent";
import clsx from "clsx";
import { useEffect, useReducer, useRef, useState } from "react";
import { saveContact } from "./service/PageService";

const initialState = {
  mail: "",
  mailError: "",
  subject: "",
  subjectError: "",
  message: "",
  messageError: "",
  controlCode: "",
  controlCodeError: "",
  contactSent: false,
};

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case "setMail":
      return { ...state, mail: action.payload };
    case "setSubject":
      return { ...state, subject: action.payload };
    case "setMessage":
      return { ...state, message: action.payload };
    case "setControlCode":
      return { ...state, controlCode: action.payload };
    case "setMailError":
      return { ...state, mailError: action.payload };
    case "setSubjectError":
      return { ...state, subjectError: action.payload };
    case "setMessageError":
      return { ...state, messageError: action.payload };
    case "setControlCodeError":
      return { ...state, controlCodeError: action.payload };
    case "resetErrors":
      return { ...state, controlCodeError: "", messageError: "", subjectError: "", mailError: "" };
    case "setContactSent":
      return { ...state, contactSent: true };
    default:
      return state;
  }
};

const ContactSentStyle = {
  display: "flex",
  justifyContent: "center",
  fontWeight: 800,
  marginTop: 60,
  fontSize: "larger",
};

const PageContact = () => {
  const articleId = "contact";

  const [article, loading] = useArticle(articleId);
  const [pluggins, setPluggins] = useState<Pluggin[] | undefined>();

  const [formContact, trigger] = useReducer(reducer, initialState);

  const controlCode = useRef(Math.floor(Math.random() * 1000) + 300);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  useEffect(() => {
    const load = async () => {
      const pluggins =
        (article?.pluggins && article?.pluggins.length > 0 && (await getPlugginsFromIds(article?.pluggins))) || [];
      setPluggins(pluggins);
    };

    load();
  }, [article]);

  if (loading || pluggins === undefined) {
    return <div></div>;
  }

  const handleHasTitle = (flag: boolean, title: string) => {
   
    if (flag) {
      document.title = `${title} - ROUGE GRAPHIQUE`;
    } else {
      document.title = `${article?.title} - ROUGE GRAPHIQUE`;
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    let hasError = false;
    trigger({ type: "resetErrors" });
    if (formContact.mail === "") {
      trigger({ type: "setMailError", payload: "Veuillez saisir votre mail" });
      hasError = true;
    }
    if (formContact.subject === "") {
      trigger({ type: "setSubjectError", payload: "Veuillez saisir un sujet" });
      hasError = true;
    }
    if (formContact.message === "") {
      trigger({ type: "setMessageError", payload: "Veuillez v un message" });
      hasError = true;
    }
    if (formContact.controlCode !== controlCode.current.toString()) {
      trigger({ type: "setControlCodeError", payload: "Veuillez saisir le code " + controlCode.current });
      hasError = true;
    }
    if (hasError) {
      return;
    }
    await saveContact({ mail: formContact.mail, subject: formContact.subject, message: formContact.message });

    trigger({ type: "setContactSent" });
  };

  return (
    <div data-page-id={article?.id || ""}>

      {article && (
        <RichTextComponent
          contextKey='page'
          contentKey={article?.id || ""}
          data={article}
          contents={(article?.layout !== "none" && article?.contents) || undefined}
          onHasTitle={handleHasTitle}
          customScript={article?.customScript}
          plugginScript={pluggins?.map(p => p.script || "")}
          plugginCss={pluggins?.map(p => p.css || "")}
        >
          {formContact.contactSent ? (
            <div style={ContactSentStyle}>
              Merci, votre message a bien été reçu et sera traité dès que possible.
            </div>
          ) : (
            <form className='box' style={{ width: "100%" }} onSubmit={handleSubmit}>
              <div className='field full-width'>
                <label className='label'>Mail</label>
                <div className='control'>
                  <input
                    className={clsx("input", formContact.mailError && "is-danger")}
                    type='email'
                    placeholder='Votre mail'
                    value={formContact.mail}
                    onChange={({ target }) => trigger({ type: "setMail", payload: target.value })}
                  />
                </div>
                {formContact.mailError && <p className='help is-danger'>{formContact.mailError}</p>}
              </div>

              <div className='field full-width'>
                <label className='label'>Sujet</label>
                <div className='control'>
                  <input
                    className={clsx("input", formContact.subjectError && "is-danger")}
                    type='text'
                    placeholder='Le sujet...'
                    value={formContact.subject}
                    onChange={({ target }) => trigger({ type: "setSubject", payload: target.value })}
                  />
                </div>
                {formContact.subjectError && <p className='help is-danger'>{formContact.subjectError}</p>}
              </div>
              <div className='field full-width'>
                <label className='label'>Message</label>
                <div className='control'>
                  <textarea
                    className={clsx("textarea", formContact.messageError && "is-danger")}
                    placeholder='Votre message...'
                    value={formContact.message}
                    onChange={({ target }) => trigger({ type: "setMessage", payload: target.value })}
                  ></textarea>
                </div>
                {formContact.messageError && <p className='help is-danger'>{formContact.messageError}</p>}
              </div>

              <div className='field full-width'>
                <label className=''>
                  Pour nous aider à diminuer les spams, veuillez saisir le code {controlCode.current} dans le champ
                  ci-dessous
                </label>
                <div className='control'>
                  <input
                    className={clsx("input", formContact.controlCodeError && "is-danger")}
                    type='text'
                    placeholder='Code de vérification...'
                    value={formContact.controlCode}
                    onChange={({ target }) => trigger({ type: "setControlCode", payload: target.value })}
                  />
                </div>
                {formContact.controlCodeError && <p className='help is-danger'>{formContact.controlCodeError}</p>}
              </div>
              <div className='field ContactForm_ButtonField__1UJGJ'>
                <button type='submit' className='button is-info'>
                  Envoyer
                </button>
              </div>
            </form>
          )}
        </RichTextComponent>
      )}
    </div>
  );
};

export default PageContact;
