import { PayPalButtons } from "@paypal/react-paypal-js";
import {
  OnApproveData,
  OnApproveActions,
  CreateOrderData,
  CreateOrderActions,
} from "@paypal/paypal-js/types/components/buttons";
import { useState, useEffect } from "react";
import useRefState from "_common/hook/useRefState";
import { roundTo } from "_common/service/FunUtil";
//import { PP_CLIENT_ID } from "_core/config/paypal";
import CartTable from "./component/CartTable";
import { Cart, cartChangedListener, CartItem, deleteCart, readCart, showCartListener } from "./service/CartService";
import { createCommand, saveCommand } from "./service/PaymentService";
import { Article, getArticle } from "business/article/service/ArticleService";
import Icon from "_common/component/element/Icon";

const PaymentPage = () => {
  const [getCart, setCart] = useRefState<Cart | undefined>(readCart());
  const [getMail, setMail] = useRefState("");
  const [getPhone, setPhone] = useRefState("");
  const [getMail2, setMail2] = useRefState("");
  const [getShippingArea, setShippingArea] = useRefState<"france" | "autres">("france");
  const [paymentStatus, setPaymentStatus] = useState<"init" | "pending" | "done" | "error">("init");
  const [getCommandId, setCommandId] = useRefState("");
  const [downloadArticles, setDownloadArticles] = useState<Article[]>();

  useEffect(() => {
    return showCartListener(({ cart }) => {
      setCart(cart);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    return cartChangedListener((cart) => {
      setCart(cart);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCartChanged = (cart: Cart) => {
    setCart(cart);
  };

  const cart = getCart();
  const shippingCosts = cart && roundTo(cart.reduce((acc, item) => acc + roundTo(item.shippingCosts || 0), 0));
  const total = (cart && roundTo(cart.reduce((acc, item) => acc + roundTo((item.applyShippingCosts ? (item.shippingCosts || 0) : 0) + item.quantity * item.price), 0))) || 0;

  const quantity = cart?.reduce((acc, item) => acc + item.quantity, 0) || 0;

  const isMailValid = () => {
    const mail = getMail();
    const mail2 = getMail2();
    if (!mail || mail !== mail2) {
      return false;
    }
    return (
      mail &&
      !!mail.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
      )
    );
  };
  const cleanPhoneNumber = (phone: string) => {
    return phone.replace(/[^0-9]/g, "").replace(/\s/g, "");
  };
  /*const isPhoneValid = () => {
    const phone = getPhone();

    if (!phone || phone.length < 10) {
      return false;
    }
    if (phone.match(/[a-zA-Z$+]/)) {
      return false;
    }
    let res = cleanPhoneNumber(phone);
    if (!res || res.length !== 10) {
      return false;
    }
    return true;
  };*/

  const handlePhoneChanged: React.ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    let phone = target.value;
    // phone = phone.replace(/\./g, "").replace(/-/g, "").replace(/\s/g, "").replace(/\+/g, "")
    setPhone(phone);
  };

  const handleShippingAreaChanged: React.ChangeEventHandler<HTMLSelectElement> = ({ target }) => {
    const cart = getCart();
    let shippingArea = target.value;
    if (cart && shippingArea === "france") {
      cart.forEach(c => c.applyShippingCosts = false)
    } else if (cart) {
      cart.forEach(c => c.applyShippingCosts = true)
    }
    // phone = phone.replace(/\./g, "").replace(/-/g, "").replace(/\s/g, "").replace(/\+/g, "")
    setShippingArea(shippingArea as "france" | "autres");
  };


  const getValidPhoneValue = () => {
    let phone = getPhone();
    phone = cleanPhoneNumber(phone);
    return phone;
  };

  const handleCreateOrder = async (_: CreateOrderData, actions: CreateOrderActions) => {
    const cart = getCart();
    const total = (cart && roundTo(cart.reduce((acc, item) => acc + roundTo(item.quantity * item.price), 0))) || 0;
    const shippingCosts = (cart && roundTo(cart.reduce((acc, item) => acc + roundTo((item.applyShippingCosts ? (item.shippingCosts || 0) : 0)), 0))) || 0;
    const quantity = cart?.reduce((acc, item) => acc + item.quantity, 0) || 0;
    setPaymentStatus("pending");

    const mail = getMail();
    if (!mail) {
      return "";
    }
    if (cart) {
      const cmd: any = {
        createdAt: new Date(),
        cart: JSON.stringify(cart),
        mail: getMail(),
        phone: getValidPhoneValue() || "",
        totalEuro: total,
        nbArticles: quantity,
        comment: "",
        status: "pending",
        payer: "",
      };

      const res = await createCommand(cmd);
      const commandId = res.id;
      setCommandId(commandId);
    }

    const commandId = getCommandId();

    let phone = getValidPhoneValue() || "";
    const orderPayer: any = {
      email_address: getMail(),
    };

    if (phone) {
      orderPayer.phone = {
        phone_type: "HOME",
        phone_number: {
          national_number: phone,
        },
      };
    }

    const fullOrder = {
      payer: orderPayer,
      purchase_units: [
        {
          description: "ROUGE GRAPHIQUE",
          soft_descriptor: commandId,
          custom_id: commandId,
          reference_id: commandId,
          amount: {
            currency_code: "EUR",
            value: roundTo(total + shippingCosts) + "",
            breakdown: {
              item_total: { currency_code: "EUR", value: total + "" },
              shipping: { currency_code: "EUR", value: shippingCosts + "" },
              tax_total: { currency_code: "EUR", value: "0" },
            },
          },
          items: cart!.map((article: CartItem) => {
            return {
              name: article.title,
              quantity: article.quantity + "",
              unit_amount: {
                currency_code: "EUR",
                value: article.price + "",
              },

              sku: article.sku,
            };
          }),
        },
      ],
    };
    return actions.order.create(fullOrder);
  };

  const handleCancel = async () => {
    console.info("Payment cancelled");
    try {
      await saveCommand(getCommandId(), {
        updatedAt: new Date(),
        status: "canceled",
      });
    } catch (err) { }
  };

  const handleError = async (err: any) => {
    let errorString = "";
    try {
      errorString = JSON.stringify(err.message);
    } catch (err) { }

    try {
      await saveCommand(getCommandId(), {
        updatedAt: new Date(),
        status: "ERROR",
        error: errorString,
      });
    } catch (err) { }

    setPaymentStatus("error");
  };
  const handleApprove = async (data: OnApproveData, actions: OnApproveActions) => {
    const cart = getCart();
    const res = await actions?.order?.capture();

    // extract order infos
    let order = "";
    try {
      order = JSON.stringify(res);
    } catch (err) { }

    // extract shipping infos
    let shipping = "";
    try {
      if (res && res.purchase_units && res.purchase_units.length > 0) {
        shipping = JSON.stringify(res.purchase_units[0].shipping || {});
      }
    } catch (err) { }

    // extract transaction id
    let transactionId = "";
    try {
      if (
        res &&
        res.purchase_units &&
        res.purchase_units.length > 0 &&
        res.purchase_units[0].payments &&
        (res.purchase_units[0].payments.captures?.length || 0) > 0
      ) {
        transactionId = (res.purchase_units[0].payments!.captures![0].id as string) || "";
      }
    } catch (err) { }

    if (cart) {
      const articleWithDownloadUrls: Article[] = [];
      for (let i = 0; i < cart?.length; i++) {
        const article = cart[i];
        if (article.isPdf) {
          const articleDetail = await getArticle(article.id);
          articleDetail && articleWithDownloadUrls.push(articleDetail);
        }
      }
      if (articleWithDownloadUrls.length > 0) {
        setDownloadArticles(articleWithDownloadUrls);
      }
    }

    let payer = "";
    try {
      payer = JSON.stringify(res?.payer || {});
    } catch (err) { }

    try {
      if (res) {
        await saveCommand(getCommandId(), {
          updatedAt: new Date(),
          status: res.status || "",
          payer: payer || "",
          orderCreateTime: res.create_time || "",
          orderUpdateTime: res.update_time || "",
          orderID: data.orderID || "",
          payerID: data.payerID || "",
          order: order,
          shipping: shipping,
          transactionId: transactionId,
        });
        if (res) {
          setPaymentStatus("done");
          deleteCart();
        }
      }
    } catch (err) { }
  };

  const cartSummary = cart
    ? `Votre panier contient ${quantity} article(s) pour un montant de ${total} euros TTC (Frais de port inclus)`
    : "Votre panier est vide";

  return (
    /* <PayPalScriptProvider options={{ "client-id": PP_CLIENT_ID, currency: "EUR" }}> */
    <>
      {paymentStatus === "done" && (
        <div className="box content m2">
          <h2>Paiement effectué</h2>
          <span className="label flex-center">
            Nous avons bien enregistré votre commande, celle-ci sera traitée dans les plus brefs délais. Nous vous
            remercions de votre confiance.
          </span>
        </div>
      )}
      {cart && !!quantity && (
        <div className="box content mt-3">
          <h2>1 - Votre Panier</h2>
          {((shippingCosts || 0) > 0) && <>   <label htmlFor="shippingArea" className="label mt-2">Sélectionnez votre pays de livraison</label>
            <p className="control has-icons-left has-icons-right mb-2">

              <select className="select pl-1 pr-1 b2 is-medium" name="shippingArea" id="shippingArea" value={getShippingArea()} onChange={handleShippingAreaChanged}>
                <option value="france">France métropolitaine - Pas de frais de port</option>
                <option value="autre">Hors France métropolitaine - Frais de port : {shippingCosts} €</option>
              </select>
            </p>
            
          </>}

          <span className="label flex-center">{cartSummary} </span>
          <CartTable cart={cart} onCartChanged={handleCartChanged} showShippingCosts={(shippingCosts || 0) > 0}/>
        </div>
      )}
      {cart && !!quantity && (
        <div className="box content">
          <h2>2 - Votre compte</h2>
          <label className="label mt-3">
            Adresse mail nécessaire pour vous informer du traitement de votre commande (saisie obligatoire) :
          </label>
          <p className="control has-icons-left has-icons-right">
            <input
              required
              type="email"
              title="Votre adresse Mail"
              placeholder="Votre adresse mail"
              className="input"
              value={getMail()}
              onChange={({ target }) => setMail(target.value)}
            />
            <span className="icon is-small is-left">
              <i className="fas fa-envelope"></i>
            </span>
          </p>

          <label className="label mt-3">Confirmez votre adresse mail (saisie obligatoire)</label>

          <p className="control has-icons-left has-icons-right">
            <input
              required
              type="email"
              title="Votre adresse Mail"
              placeholder="Confirmez votre adresse mail"
              className="input"
              value={getMail2()}
              onChange={({ target }) => setMail2(target.value)}
            />
            <span className="icon is-small is-left">
              <i className="fas fa-envelope"></i>
            </span>
          </p>

          <label className="label mt-3">Téléphone</label>

          <p className="control has-icons-left has-icons-right">
            <input
              type="tel"
              title="Votre téléphone pour vous joindre"
              placeholder="Votre téléphone pour vous joindre"
              className="input"
              value={getPhone()}
              onChange={handlePhoneChanged}
            />
            <span className="icon is-small is-left">
              <i className="fas fa-phone"></i>
            </span>
          </p>
      
        </div>
      )}
      {!!quantity && (
        <div className="box content">
          <h2>3 - Paiement et adresse de livraison</h2>
        

          <div style={{ display: "block", textAlign: "center", margin: "auto", width: "100%" }}>

          {!isMailValid() && (
            <div className="label p-2">
              Veuillez préalablement saisir votre adresse mail pour procéder au paiement...
            </div>
          )}
          {isMailValid() && (
            <>
              <div className="label p-2">
                Vos informations de paiement (code carte par exemple) sont sécurisés, nous n'y avons pas accès. Nous ne
                stockons pas d'informations concernant votre moyen de paiement.
                <br />
                NOTE : Si vous choisissez le paiement PayPal, vous n'avez pas besoin de renseigner votre adresse de
                livraison car elle est déjà définie sur votre compte PayPal.
              </div>
            </>
          )}
          <PayPalButtons
                  createOrder={handleCreateOrder}
                  onApprove={handleApprove}
                  onError={handleError}
                  onCancel={handleCancel}
                  disabled={!(isMailValid() && (paymentStatus === "init" || paymentStatus === "pending"))}
                />
          </div>
          {paymentStatus === "error" && (
            <div className="box content m2">
              <h2>Paiement en erreur</h2>
              <span className="label flex-center">
                Nous sommes désolé, le paiement est tombé en erreur, pouvez-vous &nbsp;
                <a href="/contact">nous contacter </a>&nbsp; en nous indiquant le type de navigateur et le matériels
                utilisé (Mac, PC, IPhone, Android...).
              </span>
            </div>
          )}

          {downloadArticles && downloadArticles.length > 0 && (
            <div className="box content m2">
              <h3>
                Cliquez sur les liens temporaires ci-dessous pour télécharger les livres numériques (au format pdf) que
                vous venez d'acquérir.
              </h3>
              <ul>
                {downloadArticles.map((art) => (
                  <li key={art.id}>
                    <a target="_blank" href={art.downloadUrl} rel="noreferrer">
                      {" "}
                      <Icon tabIndex={0} aria-label={`Télécharger l'e-book ${art.title}`} name="download" /> {art.title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default PaymentPage;
