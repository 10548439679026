import { useTopic } from "business/topic/service/TopicService";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { resolvePath, ROUTE_DETAIL, ROUTE_UPDATE_CONSENT } from "../App";
import Style from "./Footer.module.css";

type FooterProps = {
  footerId?: string;
};

const Footer: React.FC<FooterProps> = ({ footerId }) => {
  const [footer] = useTopic(footerId);
  return (
    <footer role="contentinfo" className={Style.Footer}>
      <ul className={clsx("columns is-multiline", Style.Columns)}>
        {footer &&
          footer.articles.map((a) => (
            <li key={a.id} className={clsx("column is-2", Style.Column)}>
              <Link className={Style.Link} to={resolvePath(ROUTE_DETAIL, { id: a.id })}>
                {a.title}
              </Link>
            </li>
          ))}
          <li key={"consents"} className={clsx("column is-2", Style.Column)}>
              <Link className={Style.Link} to={ROUTE_UPDATE_CONSENT}>
                Gérer le consentement
              </Link>
            </li>
      </ul>
    </footer>
  );
};

export default Footer;
