import { ConsultationPaymentFormFields, FormActionType } from "./ConsultationPaymentPage";

export type ConsultationPaymentFormProps = {
  value: ConsultationPaymentFormFields;
  onChange: (e: {
    type: FormActionType;
    payload: string;
  }) => void;
};


export type FormErrorType = {
  mail?: string;
  phone?: string;
};
export const isPhoneValid = (phone:string) => {
  return phone && !!phone.match(/^([+]{1}(?:[0-9\-()/.]\s?){6, 15}[0-9]{1})|[0-9]{10}$/i);
}

export const isBirthDateValid = (bd:string)=> {
  return bd && !!bd.match(/^(([012]\d)|(3[01]))\/((0\d)|(1[012]))\/((19)|(20))\d\d$/i);
}
export const isMailValid = (mail: string) => {
  return (
    mail &&
    !!mail.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
    )
  );
};
const ConsultationPaymentForm = ({ value, onChange }: ConsultationPaymentFormProps) => {

  const onPhoneChange:React.ChangeEventHandler<HTMLInputElement> = (e) => {
    let phone = e.target.value;
    onChange({ type: "phone", payload: phone });
  };
  const onMailChange:React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const mail = e.target.value;
    onChange({ type: "mail", payload: mail });
  };

  return (
    <>
      <div style={{ fontSize: "1.4rem", marginTop: 30 }}>
        Merci de préciser votre prénom, votre date de naissance, heure de naissance, ville et pays de naissance.
      </div>

      <div className="field full-width mt-2">
        <label className="label">Prénom</label>
        <div className="control">
          <input
            placeholder="Prénom..."
            className="input"
            name="firstName"
            type="text"
            onChange={(e) => onChange({ type: "firstName", payload: e.target.value })}
            value={value.firstName}
          />
        </div>
      </div>
      <div className="field full-width">
        <label className="label">Date de naissance</label>
        <div className="control">
          <input
            placeholder="JJ/MM/AAAA"
            className="input"
            name="birthDate"
            type="text"
            onChange={(e) => onChange({ type: "birthDate", payload: e.target.value })}
            value={value.birthDate}
          />
        </div>
      </div>
      <div className="field full-width">
        <label className="label">Heure de naissance</label>
        <div className="control">
          <input
            placeholder=""
            className="input"
            name="birthHour"
            type="text"
            onChange={(e) => onChange({ type: "birthHour", payload: e.target.value })}
            value={value.birthHour}
          />
        </div>
      </div>
      <div className="field full-width">
        <label className="label">Ville de naissance</label>
        <div className="control">
          <input
            placeholder="Ville..."
            className="input"
            name="birthCity"
            type="text"
            onChange={(e) => onChange({ type: "birthCity", payload: e.target.value })}
            value={value.birthCity}
          />
        </div>
      </div>
      <div className="field full-width">
        <label className="label">Pays</label>
        <div className="control">
          <input
            placeholder="Pays..."
            className="input"
            name="birthCountry"
            type="text"
            onChange={(e) => onChange({ type: "birthCountry", payload: e.target.value })}
            value={value.birthCountry}
          />
        </div>
      </div>
      <div style={{ fontSize: "1.4rem", marginTop: 30, marginBottom: 30 }}>
        Afin de vous contacter, veuillez également préciser votre numéro de téléphone et votre adresse mail
      </div>
      <div className="field full-width ">
        <label className="label">Adresse mail</label>
        <div className="control">
          <input
            placeholder="Mail..."
            className="input"
            name="mail"
            type="text"
            onChange={onMailChange}
            value={value.mail}
          />
        </div>
       
      </div>

      <div className="field full-width mb-2">
        <label className="label">Téléphone </label>
        <div className="control">
          <input
            placeholder="Téléphone..."
            className="input"
            name="phone"
            type="text"
            onChange={onPhoneChange}
            value={value.phone}
          />
        </div>
      
      </div>
    </>
  );
};

export default ConsultationPaymentForm;
