import { Topic } from "business/topic/service/TopicService";
import Carousel from "_common/component/carousel/Carousel";
import Style from "./TopicAsCarousel.module.css";
import SmallCardArticle from "./SmallCardArticle";
import { Article, getArticlesFromIds } from "business/article/service/ArticleService";
import { useEffect, useState } from "react";

type TopicAsCarouselProps = React.InputHTMLAttributes<HTMLDivElement> & {
  topic: Topic;
};
const TopicAsCarousel: React.FC<TopicAsCarouselProps> = ({ topic }) => {
  const [articles, setArticles] = useState<Article[]>([]);
  useEffect(() => {
    const load = async () => {
      const ids = topic.articles.map((a) => a.id);
      const articles = await getArticlesFromIds(topic.articles.map((a) => a.id));
      articles.sort ( (a, b) => {
        return ids.indexOf(a.id) - ids.indexOf(b.id);
      });

      setArticles(articles);
    };
    load();
  }, [topic]);

  return (
    <div>
      <Carousel
        className={Style.Carousel}
        itemClassName={Style.ItemClassName}
        intersectionObserverThreshold={0.9}
        intersectionObserverMargin="5%"
      >
        {articles.map((article: Article, index: number) => {
          return <SmallCardArticle key={index + "." + article.id} article={article} />;
        })}
      </Carousel>
    </div>
  );
};

export default TopicAsCarousel;
