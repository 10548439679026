import { useEffect, useState } from "react";
import { later } from "_common/service/FunUtil";
import { isArray, isString } from "_common/type/utils";

// component that wrap a function that render component asynchro.
const Async = ({ children, onRendered }: any) => {
  const [result, setResult] = useState();
  useEffect(() => {
    if (children) {
      let dirty: boolean = false;
      const setDirty = () => {
        dirty = true;
      };

      // TODO should throw result !!
      const load = async () => {
        if (isArray(children)) {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          children = children.filter((c) => c && !isString(c)).pop();
        }
        const res = await children;
        if (dirty) {
          return;
        }
        setResult(res);
      };
      load();
      return setDirty;
    }
  }, [children]);

  useEffect(() => {
    if (result) {
      const triggerEvent = async () => {
        await later(100);
        onRendered && onRendered();
      };

      triggerEvent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  if (result) {
    return <>{result}</>;
  } else {
    return null;
  }
};

export default Async;
