import { Article } from "business/article/service/ArticleService";
import { Link } from "react-router-dom";
import Style from "./SmallCardArticle.module.css";

type SmallCardArticleProps = {
  article: Article;
};

const SmallCardArticle: React.FC<SmallCardArticleProps> = ({ article }) => {
  const renderArticleBody = () => {
    return (
      <figure role="figure" className={Style.Image}>
        <img src={article.thumbnail} alt="" />
        <figcaption className={Style.CardCaption}>{article.shortTitle || article.title}</figcaption>
      </figure>
    );
  };

  return (
    <Link to={`/${article.id}`}>
      <div className={Style.Card}>
        <div className={Style.CardContent}>
          {article && renderArticleBody()}
        </div>
      </div>
    </Link>
  );
};
export default SmallCardArticle;
