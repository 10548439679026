import { replaceAll } from "_common/service/FunUtil";
import { Dictionary } from "_common/type/utils";

import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { PP_CLIENT_ASTRO_ID } from "_core/config/paypal";
import { useEffect, useReducer, useRef, useState } from "react";
import { sessionStorageObjectRead } from "_common/service/SessionStorageService";

import { PayPalButtons } from "@paypal/react-paypal-js";
import {
  OnApproveData,
  OnApproveActions,
  CreateOrderData,
  CreateOrderActions,
} from "@paypal/paypal-js/types/components/buttons";
import useRefState from "_common/hook/useRefState";
import { roundTo } from "_common/service/FunUtil";
import { createCommand, saveCommand } from "./service/PaymentService";
import ConsultCalendar from "business/consultation/component/ConsultCalendar";
import { createConsultation, getConsultations } from "business/consultation/service/ConsultationService";
import { DayOfMonth } from "_common/service/DateUtil";
import ConsultationPaymentForm, { isBirthDateValid, isMailValid, isPhoneValid } from "./ConsultationPaymentForm";

import Style from './ConsultationPaymentPage.module.css'
import clsx from "clsx";
import Icon from "_common/component/element/Icon";

export const resolvePath = (path: string, variables: Dictionary) => {
  if (path.indexOf(":") > -1) {
    Object.keys(variables).forEach((key) => {
      path = replaceAll(path, ":" + key, variables[key]);
    });
  }
  return path;
};

export type ConsultationPaymentFormFields = {
  firstName: string;
  birthDate: string;
  birthHour: string;
  birthCity: string;
  birthCountry: string;
  mail: string;
  phone: string;
};
export type FormActionType = "firstName" | "birthDate" | "birthHour" | "birthCity" | "birthCountry" | "mail" | "phone"
type FormReducerType = (state: ConsultationPaymentFormFields, action: { type: FormActionType, payload: string }) => ConsultationPaymentFormFields;

const formReducer: FormReducerType = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case "mail":
      return {
        ...state,
        mail: payload
      };
    case "phone":
      return {
        ...state,
        phone: payload
      };
    case "firstName":
      return {
        ...state,
        firstName: payload
      };
    case "birthDate":
      return {
        ...state,
        birthDate: payload
      };
    case "birthHour":
      return {
        ...state,
        birthHour: payload
      };
    case "birthCity":
      return {
        ...state,
        birthCity: payload
      };
    case "birthCountry":
      return {
        ...state,
        birthCountry: payload
      };
    default:
      return state;
  }
}

const ConsultationPaymentPage = () => {


  const article = sessionStorageObjectRead("consultation-rendez-vous");
  const [paymentStatus, setPaymentStatus] = useState<"init" | "pending" | "done" | "error">("init");
  const [getCommandId, setCommandId] = useRefState("");


  const [formModel, trigger] = useReducer<FormReducerType>(formReducer, {
    firstName: "",
    birthDate: "",
    birthHour: "",
    birthCity: "",
    birthCountry: "",
    mail: "",
    phone: ""
  })

  const [getMail, setMail] = useRefState("");
  const [getPhone, setPhone] = useRefState("");
  const [getConsultation, setConsultation] = useRefState<DayOfMonth | { date: Date, label: string, precision: string }>({ date: new Date(), label: "", precision: "" });

  const formValues = useRef<ConsultationPaymentFormFields>();

  useEffect(() => {

    formValues.current = { ...formModel };
    if (formModel.mail !== getMail()) {
      setMail(formModel.mail)
    }
    if (formModel.phone !== getPhone()) {
      setPhone(formModel.phone)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formModel])

  const getValidPhoneValue = () => {
    let phone = getPhone();
    phone = phone.trim();
    return phone;
  };

  const getFormModel = () => {
    return formValues.current || formModel;
  }

  const [consultations, setConsultations] = useState<
    {
      date: any;
      createdAt: any;
      updatedAt: any;
      label: string;
      precision: string;
      comment?: string | undefined;
    }[]
  >();

  useEffect(() => {
    (async () => {
      const consultations = await getConsultations();
      setConsultations(consultations);
    })();
  }, []);

  const handleSelectDate = (d: DayOfMonth) => {
    article.consultation = d;
    setConsultation(d);
  };

  const handleCreateOrder = async (_: CreateOrderData, actions: CreateOrderActions) => {
    const total = article.price;
    const shippingCosts = 0;

    setPaymentStatus("pending");

    const mail = getMail();

    if (!mail) {
      return "";
    }

    const fm = getFormModel();
    const cartItem = {
      id: 'rdv' + (new Date().getTime()),
      title: `${article.title} - Le ${getConsultation().label} à ${getConsultation().precision} - téléphone ${fm.phone}`,
      shortTitle: article.shortTitle,
      thumbnail: article.thumbnail,
      sku: article.sku,
      price: article.price,
      shippingCosts: 0,
      applyShippingCosts: false,
      quantity: 1,
      isPdf: false,
      isConsultation: true,
      firstName: fm.firstName,
      birthDate: fm.birthDate,
      birthHour: fm.birthHour,
      birthCity: fm.birthCity,
      birthCountry: fm.birthCountry
    }

    if (article) {
      const cmd: any = {
        createdAt: new Date(),
        cart: JSON.stringify([cartItem]),
        mail: getMail(),
        phone: getValidPhoneValue() || "",
        totalEuro: total,
        nbArticles: 1,
        comment: "",
        status: "pending",
        payer: "",
      };

      const res = await createCommand(cmd);
      const commandId = res.id;
      setCommandId(commandId);

    }

    const commandId = getCommandId();

    let phone = getValidPhoneValue() || "";
    const orderPayer: any = {
      email_address: getMail(),
    };

    if (phone) {
      orderPayer.phone = {
        phone_type: "HOME",
        phone_number: {
          national_number: phone,
        },
      };
    }

    const fullOrder = {
      payer: orderPayer,
      purchase_units: [
        {
          description: "ROUGE GRAPHIQUE",
          soft_descriptor: commandId,
          custom_id: commandId,
          reference_id: commandId,
          amount: {
            currency_code: "EUR",
            value: roundTo(total + shippingCosts) + "",
            breakdown: {
              item_total: { currency_code: "EUR", value: total + "" },
              shipping: { currency_code: "EUR", value: shippingCosts + "" },
              tax_total: { currency_code: "EUR", value: "0" },
            },
          },
          items: [
            {
              name: article.title,
              quantity: "1",
              unit_amount: {
                currency_code: "EUR",
                value: article.price + "",
              },

              sku: article.sku,
            },
          ],
        },
      ],
    };
    return actions.order.create(fullOrder);
  };

  const handleCancel = async () => {
    console.info("Payment cancelled");
    try {
      await saveCommand(getCommandId(), {
        updatedAt: new Date(),
        status: "canceled",
      });
    } catch (err) { }
  };

  const handleError = async (err: any) => {
    let errorString = "";
    try {
      errorString = JSON.stringify(err.message);
    } catch (err) { }

    try {
      await saveCommand(getCommandId(), {
        updatedAt: new Date(),
        status: "ERROR",
        error: errorString,
      });
    } catch (err) { }

    setPaymentStatus("error");
  };
  const handleApprove = async (data: OnApproveData, actions: OnApproveActions) => {
    const res = await actions?.order?.capture();

    // extract order infos
    let order = "";
    try {
      order = JSON.stringify(res);
    } catch (err) { }

    // extract shipping infos
    let shipping = "";
    try {
      if (res && res.purchase_units && res.purchase_units.length > 0) {
        shipping = JSON.stringify(res.purchase_units[0].shipping || {});
      }
    } catch (err) { }

    // extract transaction id
    let transactionId = "";
    try {
      if (
        res &&
        res.purchase_units &&
        res.purchase_units.length > 0 &&
        res.purchase_units[0].payments &&
        (res.purchase_units[0].payments.captures?.length || 0) > 0
      ) {
        transactionId = (res.purchase_units[0].payments!.captures![0].id as string) || "";
      }
    } catch (err) { }

    let payer = "";
    try {
      payer = JSON.stringify(res?.payer || {});
    } catch (err) { }

    try {
      if (res) {
        await saveCommand(getCommandId(), {
          updatedAt: new Date(),
          status: res.status || "",
          payer: payer || "",
          orderCreateTime: res.create_time || "",
          orderUpdateTime: res.update_time || "",
          orderID: data.orderID || "",
          payerID: data.payerID || "",
          order: order,
          shipping: shipping,
          transactionId: transactionId,
        });
        if (res) {
          setPaymentStatus("done");
          createConsultation({ ...getConsultation(), commandId: getCommandId() });
        }
      }
    } catch (err) { }
  };

  const isFormCorrect = (formModel.birthCity && formModel.birthCountry && isBirthDateValid(formModel.birthDate) && formModel.firstName);
  const paymentButtonEnabled = !!(getConsultation().label && isFormCorrect && isMailValid(formModel.mail) && isPhoneValid(getPhone()) && (paymentStatus === "init" || paymentStatus === "pending"));
  return (

    <PayPalScriptProvider options={{ "client-id": PP_CLIENT_ASTRO_ID, currency: "EUR" }}>
      <header role="banner">
        <nav className={clsx("navbar", Style.NavBar)} role="navigation" aria-label="main navigation">
          <div className={clsx("navbar-brand", Style.NavBarBrand)}><a href="/"><div className={Style.Bearer}><span>Rouge Graphique</span><span>É D I T E U R</span></div></a></div>
          <menu className={clsx("navbar-menu", Style.menu)}><a className={Style.retourBtn} href={article.back || "/consultation-astrologique"}> <Icon name='circle-left' />  RETOUR</a></menu>
        </nav>
      </header>
      <div className={clsx("columns m-4", Style.content)}>
        <div className="column is-4">

          <figure style={{ display: "flex", justifyContent: "center" }}><img alt="" src={article.thumbnail} /></figure>

        </div>

        <div className="column is-8">
          <p style={{ lineHeight: "1.2" }}>
            <strong className={Style.articleTitle}>{article.title}</strong>
          </p>
          <p style={{ lineHeight: '1.2', marginBottom: "50px" }}>
            <span className={Style.articleSummary}>{article.summary}</span>
          </p>

          <h2 className={Style.h2}>1 - Date du rendez-vous</h2>
          <ConsultCalendar onChange={handleSelectDate} consultations={consultations} />

          <h2 className={Style.h2}>2 - Quelques informations pour dresser votre thème</h2>
          <ConsultationPaymentForm value={formModel} onChange={trigger} />

          <h2 className={Style.h2}>3 - Procéder au paiement de {article.price} €</h2>
          <div className={Style.blocPaiement}>
            {paymentButtonEnabled && (
              <>
                <div className="label p-2">
                  Vos informations de paiement (code carte par exemple) sont sécurisés, nous n'y avons pas accès. <br /> Nous ne
                  stockons pas d'informations concernant votre moyen de paiement.
                </div>
              </>
            )}

            {!getConsultation().label && <div className="label p-2 gray  mb-2">
              Veuillez préalablement saisir votre date de rendez-vous pour procéder au paiement ...
            </div>}
            {getConsultation().label && (!isFormCorrect) && <div className="label p-2 gray mb-2">
              Merci de préciser votre prénom, votre date de naissance, heure de naissance, ville et pays de naissance pour procéder au paiement ...
            </div>}

            {getConsultation().label && isFormCorrect && (!isMailValid(formModel.mail) || !(isPhoneValid(getPhone()))) && <div className="label p-2 gray mb-2">
              Veuillez préalablement saisir votre adresse mail et votre numéro de téléphone pour procéder au paiement ...
            </div>}

            <PayPalButtons
              createOrder={handleCreateOrder}
              onApprove={handleApprove}
              onError={handleError}
              onCancel={handleCancel}
              disabled={!paymentButtonEnabled}
            />

          </div>
          {paymentStatus === "done" && (
            <div className="box content m2">
              <h2>Paiement effectué</h2>
              <span className="label">
                Nous avons bien enregistré votre rendez-vous pour le {getConsultation().label} à {getConsultation().precision} et votre paiement. En cas de changement de téléphone ou de mail, merci de <a href="/contact">nous contacter ici</a> <br /> Nous vous
                remercions de votre confiance.
              </span>
            </div>
          )}
          {paymentStatus === "error" && (
            <div className="box content m2">
              <h2 >Paiement en erreur</h2>
              <span className="label flex-center">
                Nous sommes désolé, le paiement est tombé en erreur, pouvez-vous &nbsp;
                <a href="/contact">nous contacter </a>&nbsp; en nous indiquant le type de navigateur et le matériels
                utilisé (Mac, PC, IPhone, Android...).
              </span>
            </div>
          )}
        </div>
      </div>
    </PayPalScriptProvider>

  );
};

export default ConsultationPaymentPage;
