import { Article, getArticle } from "business/article/service/ArticleService";
import React from "react";
import { CSSProperties } from "react";
import { Link } from "react-router-dom";
import Async from "_common/component/helper/Async";
import RichTextComponent from "../RichTextComponent";
import Style from "./richTextRenderer.module.css";
import TopicAsCarousel from "business/topic/component/TopicAsCarousel";
import { getTopic } from "business/topic/service/TopicService";
import { isExternUrl } from "_common/service/DomUtil";
import Icon from "_common/component/element/Icon";
import { addToCart } from "business/payment/service/CartService";
import { sessionStorageObjectWrite } from "_common/service/SessionStorageService";

export const ELEMENT_RICH_TEXT_EMBED = "article";

export type RenderContext = {
  index: number;
  contentKey: string;
  contextKey: string;
  name: string;
  parent?: RenderContext | undefined;
  page: {
    article?: Article;
  };
};

const hasParent = (name: string, context: RenderContext): boolean => {
  return !!(context.name === name || (context.parent && hasParent(name, context.parent)));
};

const mediaQuery = window.matchMedia("(max-width: 768px)");

let _isMobile = mediaQuery.matches;
let _isDesktop = !_isMobile;
mediaQuery.addEventListener("change", () => {
  _isMobile = mediaQuery.matches;
  _isDesktop = !_isMobile;
});

export const isDesktop = () => _isDesktop;

function renderStyledContent(content: any, defStyle?: CSSProperties) {
  const style: CSSProperties = defStyle || {};
  if (content.bold) {
    style.fontWeight = 700;
  }
  if (content.italic) {
    style.fontStyle = "italic";
  }
  if (content.underline) {
    style.textDecoration = "underline";
  }
  if (content.fontFamily) {
    style.fontFamily = content.fontFamily;
  }
  if (content.fontSize) {
    style.fontSize = content.fontSize;
  }

  if (content.lineHeight) {
    style.lineHeight = content.lineHeight;
  }

  if (content.color) {
    style.color = content.color;
  }
  if (content.background) {
    style.background = content.background;
  }

  if (content.code) {
    style.fontFamily = "monospace";
  }
  if (content.align === "center") {
    style.textAlign = "center";
  } else if (content.align === "right") {
    style.textAlign = "right";
  } else if (content.align === "left") {
    style.textAlign = "left";
  } else if (content.align === "justify") {
    style.textAlign = "justify";
  }
  return style;
}

async function renderText(content: any, context: RenderContext) {
  const style: CSSProperties = renderStyledContent(content);

  let text: any = content.text;
  if (content.strikethrough) {
    text = <span style={{ textDecoration: "line-through" }}>{text}</span>;
  }
  let className = "";
  if (content.kbd) {
    className += Style.Kbd + " ";
  }
  if (content.highlight) {
    className += Style.Highlight + " ";
  }

  if (_isMobile && hasParent("h1", context)) {
    style.fontSize = "2.5rem";
    style.lineHeight = "2.5rem";
  } else if (_isMobile && hasParent("h2", context)) {
    style.fontSize = "1.8rem";
    style.lineHeight = "1.8rem";
  } else if (_isMobile && (style.fontSize === "109px" || style.fontSize === "92px")) {
    style.fontSize = "3.8rem";
    style.marginLeft = "-3rem";
  } else if (_isMobile && style.fontSize === "4.8rem") {
    style.fontSize = "3.8rem";
    style.lineHeight = "3.8rem";
  }

  if (content.bold) {
    return (
      <strong key={"string" + context.index} style={style} className={className}>
        {text}
      </strong>
    );
  }
  return (
    <span key={"span" + context.index} style={style} className={className}>
      {text}
    </span>
  );
}
async function computeIndent(style: CSSProperties, indent: number | undefined) {
  if (indent) {
    style.marginLeft = indent * 25 + "px";
  }
}
async function renderH1(content: any, context: RenderContext) {
  const style = renderStyledContent(content, {});
  computeIndent(style, content.indent);
  if (_isMobile) {
    style.fontSize = "2.5rem";
    style.lineHeight = "2.5rem";
  }

  return (
    <h1 key={"h1" + context.index} className={Style.Titre1} style={style}>
      <ContentArray context={{ ...context, name: "h1", parent: context }}>{content.children}</ContentArray>
    </h1>
  );
}
async function renderH2(content: any, context: RenderContext) {
  const style = renderStyledContent(content, {});
  computeIndent(style, content.indent);
  if (_isMobile) {
    style.fontSize = "1.8rem";
    style.lineHeight = "1.8rem";
  }
  return (
    <h2 key={"h2" + context.index} className={Style.Titre2} style={style}>
      <ContentArray context={{ ...context, name: "h2", parent: context }}>{content.children}</ContentArray>
    </h2>
  );
}
async function renderH3(content: any, context: RenderContext) {
  const style = renderStyledContent(content, {});
  computeIndent(style, content.indent);

  return (
    <h3 key={"h3" + context.index} className={Style.Titre3} style={style}>
      <ContentArray context={{ ...context, name: "h3", parent: context }}>{content.children}</ContentArray>
    </h3>
  );
}
async function renderParagraph(content: any, context: RenderContext) {
  const style: CSSProperties = renderStyledContent(content);

  computeIndent(style, content.indent);

  if (content.children.length === 1 && content.children[0].text === "") {
    return (
      <p key={"p" + context.index} style={style}>
        <br />
      </p>
    );
  }

  return (
    <p key={"p" + context.index} style={style}>
      <ContentArray context={{ ...context, name: "p", parent: context }}>{content.children}</ContentArray>
    </p>
  );
}
async function renderOl(content: any, context: RenderContext) {
  const style = renderStyledContent(content);
  computeIndent(style, content.indent);

  return (
    <ol key={"ol" + context.index} style={style}>
      <ContentArray context={{ ...context, name: "ol", parent: context }}>{content.children}</ContentArray>
    </ol>
  );
}
async function renderLi(content: any, context: RenderContext) {
  const style = renderStyledContent(content);
  computeIndent(style, content.indent || 0 + 2);

  return (
    <li key={"li" + context.index} style={style}>
      <ContentArray context={{ ...context, name: "li", parent: context }}>{content.children[0].children}</ContentArray>
    </li>
  );
}
async function renderUl(content: any, context: RenderContext) {
  const style = renderStyledContent(content);
  computeIndent(style, content.indent);

  return (
    <ul key={"ul" + context.index} style={style}>
      <ContentArray context={{ ...context, name: "ul", parent: context }}>{content.children}</ContentArray>
    </ul>
  );
}

async function renderTable(content: any, context: RenderContext) {
  const style = renderStyledContent(content, {
    width: "100%",
  });
  computeIndent(style, content.indent);

  return (
    <table key={"table" + context.index} style={style}>
      <tbody key={"body" + context.index}>
        <ContentArray context={{ ...context, name: "table-body", parent: context }}>{content.children}</ContentArray>
      </tbody>
    </table>
  );
}

async function renderTd(content: any, context: RenderContext) {
  const style = renderStyledContent(content, {
    padding: "0.24rem",
  });
  computeIndent(style, content.indent);

  return (
    <td key={"td" + context.index} style={style}>
      <ContentArray context={{ ...context, name: "td", parent: context }}>{content.children}</ContentArray>
    </td>
  );
}

async function renderTr(content: any, context: RenderContext) {
  const style = renderStyledContent(content);
  computeIndent(style, content.indent);

  return (
    <tr key={"tr" + context.index} style={style}>
      <ContentArray context={{ ...context, name: "tr", parent: context }}>{content.children}</ContentArray>
    </tr>
  );
}

async function renderImage(content: any, context: RenderContext) {
  const style = renderStyledContent(content, {
    display: "flex",
    justifyContent: "center",
  });
  computeIndent(style, content.indent);

  let alt = "";
  if (content.caption && content.caption.length > 0) {
    alt = content.caption[0].text;
  }
  if (alt.indexOf("https://www.amazon.fr/") > -1) {
    return (
      <a href={alt} key={"abutton" + context.index} style={style} target="_blank" rel="noopener noreferrer nofollow">
        <figure key={"img" + context.index} style={style}>
          <img alt={"Consulter sur Amazon"} style={{ width: content.width + "px" }} src={content.url} />
        </figure>
      </a>
    );
  }
  return (
    <figure key={"img" + context.index} style={style}>
      <img alt={alt} style={{ width: content.width + "px" }} src={content.url} />
    </figure>
  );
}

async function renderRichTextContent(content: any, context: RenderContext) {
  const uid = content.uid;
  const contentType = content.contentType;

  if (contentType === "article" || contentType === "product") {
    const article = await getArticle(uid);
    return (
      article && (
        <article key={"article" + context.index}>
          <RichTextComponent
            contextKey="embed"
            contentKey={uid}
            data={article}
            contents={(article?.layout !== "none" && article?.contents) || undefined}
            customScript={article?.customScript}
          />
        </article>
      )
    );
  } else if (contentType === "topic") {
    const topic = await getTopic(uid);
    return (
      (topic && (
        <article key={"article-topic" + uid + "." + context.index}>
          <TopicAsCarousel topic={topic} />
        </article>
      )) ||
      null
    );
  }
}

async function renderMedia(content: any, context: RenderContext) {
  const style = {};
  computeIndent(style, content.indent);

  return (
    <iframe
      key={"media" + content.url + context.index}
      width="100%"
      height={315}
      src={content.url + "?modestbranding=1"}
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; gyroscope; picture-in-picture"
      allowFullScreen={true}
    ></iframe>
  );
}
async function renderButton(content: any, context: RenderContext) {
  const style: CSSProperties = {};
  const buttonStyle = renderStyledContent(content, {
    cursor: "pointer",
    fontWeight: 700,
  });

  style.color = "rgba(0, 0, 0, 0.87)";
  style.textDecoration = "none";
  buttonStyle.minWidth = 140;
  buttonStyle.minHeight = 44;
  buttonStyle.font = "normal normal normal 16px/1.4em sans-serif";
  buttonStyle.color = "rgb(14, 52, 90)";
  buttonStyle.borderColor = "rgb(14, 52, 90)";
  buttonStyle.borderWidth = 3;

  computeIndent(style, content.indent);

  if (content.dataKey === "add-to-cart" && context.page.article) {
    const addProduct = (article?: Article) => {

      context.page.article && addToCart(context.page.article);
    };


    const handleAddProduct = async (article: Article | undefined) => {
      if (context.page?.article?.themeAstral) {

        sessionStorageObjectWrite("consultation-rendez-vous", {
          id: article?.id,
          title: article?.title,
          price: article?.price,
          thumbnail: article?.thumbnail,
          shortTitle: article?.shortTitle,
          summary: article?.summary,
          sku: article?.sku,
          back: window.location.href,
        });
        window.history.replaceState({}, "", "/consultation-rendez-vous");

        // Reload the page to reflect the new parameter value
        window.location.reload();
      } else {
        addProduct(article);
      }
    };

    buttonStyle.fontWeight = 700;
    buttonStyle.backgroundColor = "rgb(14, 52, 90)";
    buttonStyle.paddingRight = "1rem";
    buttonStyle.marginTop = "0.2rem";
    buttonStyle.color = "white";
    return (
      <>

        <button
          key={"cart-button" + context.index}
          type="button"
          style={buttonStyle}
          onClick={() => handleAddProduct(context?.page?.article)}
        >
          <Icon name={context.page.article.themeAstral ? "calendar-days" : "bag-shopping"} style={{ marginRight: 10 }} />
          {context.page.article.themeAstral && "Prendre un rendez-vous"}
          {!context.page.article.themeAstral && (
            <ContentArray context={{ ...context, name: "button", parent: context }}>{content.children}</ContentArray>
          )}
        </button>
      </>
    );
  }
  if (isExternUrl(content.url)) {
    return (
      <a
        href={content.url}
        key={"abutton" + context.index}
        style={style}
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        <button type="button" style={buttonStyle}>
          <ContentArray
            key={context.index + context.contentKey}
            context={{ ...context, name: "button", parent: context }}
          >
            {content.children}
          </ContentArray>
        </button>
      </a>
    );
  }
  if (content.url) {
    return (
      <Link to={content.url} key={"lnkbtn" + context.index} style={style}>
        <button type="button" style={buttonStyle}>
          <ContentArray key={context.index + context.contentKey} context={{ ...context, name: "btn", parent: context }}>
            {content.children}
          </ContentArray>
        </button>
      </Link>
    );
  } else {
    return <button type="button" style={buttonStyle}>
      <ContentArray key={context.index + context.contentKey} context={{ ...context, name: "btn", parent: context }}>
        {content.children}
      </ContentArray>
    </button>
  }
}
async function renderLink(content: any, context: RenderContext) {
  const style: CSSProperties = renderStyledContent(content);

  style.color = "rgba(0, 0, 0, 0.87)";
  style.fontWeight = 700;
  style.textDecoration = "underline";

  computeIndent(style, content.indent);

  if (isExternUrl(content.url)) {
    return (
      <a
        key={"alnk" + context.index}
        href={content.url}
        style={style}
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        <ContentArray key={context.index + context.contentKey} context={{ ...context, name: "link", parent: context }}>
          {content.children}
        </ContentArray>
      </a>
    );
  }
  return (
    <Link key={"lnk" + context.index} to={content.url} style={style}>
      <ContentArray key={context.index + context.contentKey} context={{ ...context, parent: context }}>
        {content.children}
      </ContentArray>
    </Link>
  );
}

async function renderBlockquote(content: any, context: RenderContext) {
  let style: CSSProperties = {};

  style.marginTop = "0.5rem";
  style.marginBottom = "0.5rem";
  style.marginLeft = 0;
  style.marginRight = 0;
  style.borderLeft = "2px solid #ddd";
  style.padding = "10px 20px 10px 16px";
  style.color = "#aaa";

  style = await renderStyledContent(content, style);

  computeIndent(style, content.indent);

  return (
    <blockquote key={"blq" + context.index} style={style}>
      <ContentArray
        key={context.index + context.contentKey}
        context={{ ...context, name: "blockquote", parent: context }}
      >
        {content.children}
      </ContentArray>
    </blockquote>
  );
}

const renderContent = (context: RenderContext) => async (content: any, index: number) => {
  context.index = index;

  if (content.text) {
    return renderText(content, context);
  }
  switch (content.type) {
    case "p":
      return renderParagraph(content, context);
    case "h1":
      return renderH1(content, context);
    case "h2":
      return renderH2(content, context);
    case "h3":
      return renderH3(content, context);
    case "ol":
      return renderOl(content, context);
    case "li":
      return renderLi(content, context);
    case "ul":
      return renderUl(content, context);
    case "a":
      return renderLink(content, context);
    case "table":
      return renderTable(content, context);
    case "tr":
      return renderTr(content, context);
    case "td":
      return renderTd(content, context);
    case "button":
      return renderButton(content, context);
    case "img":
      return renderImage(content, context);
    case "media_embed":
      return renderMedia(content, context);
    case "blockquote":
      return renderBlockquote(content, context);
    case ELEMENT_RICH_TEXT_EMBED:
      return renderRichTextContent(content, context);
    default:
      return renderText(content, context);
  }
};

export const ContentArray: React.FC<{ context: RenderContext }> = ({ context, children }: any) => {
  const rendererWithContext = renderContent(context);
  if (children.length <= 3) {
    // cas d'une image dans un bloc (on enlève les p vides)
    children = children.reduce((arr: any, c: any) => {
      if (arr.length > 0 && c.type === "img") {
        const prev = arr[arr.length - 1];
        if (prev.type === "p" && prev.children.length === 1 && prev.children[0].text === "") {
          arr.pop();
        }
      } else if (arr.length === 1 && arr[0].type === "img") {
        if (c.type === "p" && c.children.length === 1 && c.children[0].text === "") {
          return arr;
        }
      }
      arr.push(c);
      return arr;
    }, []);
  }

  if (children && children.length > 0) {
    return (
      <Async key={context.index + context.contentKey}>
        {children && Promise.all(children.map(rendererWithContext))}
      </Async>
    );
  } else {
    return null;
  }
};
