import { Dictionary, isDictionary, isFunction, isString } from "../type/utils";

export function escapeRegExp(s: string): string {
  return s.replace(/([.*+?^=!:${}()|[\]/\\])/g, "\\$1");
}

export function replaceAll(str: string, toFind: string, toReplace: string): string {
  return str.replace(new RegExp(escapeRegExp(toFind), "g"), toReplace);
}

export const debounce = (wait: number, callback: Function) => {
  let timeout: number;
  return (...args: any[]) => {
    const next = () => callback(...args);
    clearTimeout(timeout);
    timeout = setTimeout(next, wait) as any;
  };
};
export function later(durationMsOrPredicat: number | (() => boolean) = 10) {
  if (isFunction(durationMsOrPredicat)) {
    return new Promise(async (resolve, reject) => {
      let cpt = 0;

      while (!durationMsOrPredicat()) {
        cpt++;
        await later(500);
        if (cpt > 1000) {
          reject();
        }
      }
      resolve(undefined);
    });
  } else {
    return new Promise((resolve) => {
      setTimeout(resolve, durationMsOrPredicat);
    });
  }
}

export function guid() {
  // source : https://blogs.cozi.com/tech/2010/04/generating-uuids-in-javascript.html
  // avec ajout de la date en plus :-)
  let uuid = new Array(36);
  const data = [
    Math.floor(0x100000000 * Math.random()) & 0xffffffff,
    (Math.floor(0x100000000 * Math.random()) & 0xffff0fff) | (4 << 12), // version (1-5)
    (Math.floor(0x100000000 * Math.random()) & 0x3fffffff) | 0x80000000, // rfc 4122 variant
    Math.floor(0x100000000 * Math.random()) & 0xffffffff,
    new Date().getTime(),
  ];
  for (let i = 0, k = 0; i < 5; i++) {
    var rnd = data[i];
    for (let j = 0; j < 8; j++) {
      if (k === 8 || k === 13 || k === 18 || k === 23) {
        uuid[k++] = "-";
      }
      let r = (rnd >>> 28) & 0xf; // Take the high-order nybble
      rnd = (rnd & 0x0fffffff) << 4;
      uuid[k++] = "0123456789abcdef".charAt(r);
    }
  }
  return uuid.join("");
}

export function slsx(...args: any[]): Object {
  if (args) {
    return args.reduce((accumulator: any, currentValue: any) => {
      if (currentValue === undefined || currentValue === null) {
        return accumulator;
      }
      if (isDictionary(currentValue)) {
        const validObject = Object.keys(currentValue)
          .filter((k) => currentValue[k] !== null && currentValue[k] !== undefined)
          .reduce((obj, key) => {
            obj[key] = currentValue[key];
            return obj as Dictionary;
          }, {} as Dictionary);

        return { ...accumulator, ...validObject };
      } else if (isString(currentValue)) {
        const parts = currentValue.split(":", 1);
        accumulator[parts[0]] = parts[1];
        return accumulator;
      } else if (isFunction(currentValue)) {
        return { ...accumulator, ...slsx(currentValue()) };
      } else {
        return accumulator;
      }
    }, {});
  } else {
    return {};
  }
}

/**
 *
 * Permet de créer un nouveau tableau en excluant certaines valeurs
 *
 * @param predicat indique si un élément du tableau source est égal à l'élément du tableau des éléments à exclure
 * @param source le tableau source à partir duquel on veut créer un tableau avec des éléments exclus
 * @param toExclude le tableau des éléments à exclure
 */
export function excludeArray<T>(equals: (a: T, b: T) => boolean) {
  return (source: T[] | undefined, toExclude: T[] | undefined) => {
    if (!source) {
      return [];
    }
    if (!toExclude || toExclude.length === 0) {
      return [...source];
    }
    return source.filter((a) => toExclude.filter((b) => equals(a, b)).length === 0);
  };
}

export function deduplicate<T>(a: T[], predicate?: (a: T, b: T) => boolean): T[] {
  if (predicate) {
    const result = [];
    const endIndex = a.length - 1;
    for (let i = endIndex; i >= 0; i--) {
      const value = a[i];
      const idx = a.findIndex((v: T) => predicate(v, value));
      if (idx === i) {
        result.push(value);
      }
    }
    return result;
  }
  return a.filter((value, index, self) => {
    return self.indexOf(value) === index;
  });
}

export function hasDuplicate<T>(a: T[], predicate?: (a: T, b: T) => boolean): boolean {
  if (predicate) {
    const endIndex = a.length - 1;
    for (let i = endIndex; i >= 0; i--) {
      const value = a[i];
      const idx = a.findIndex((v: T) => predicate(v, value));
      if (idx !== i) {
        return true;
      }
    }
    return false;
  }
  return new Set(a).size === (a || []).length;
}

// check if arrays have same items in same order
// only shallow compare items.
export function areArraysShallowEqual(first: any[], second: any[]) {
  if (!first && !second && first === second) {
    return true;
  }
  if (!first || !second) {
    return false;
  }
  if (first.length !== second.length) {
    return false;
  }

  for (let i = 0; i < first.length; i++) {
    if (second[i] !== first[i]) {
      return false;
    }
  }

  return true;
}

export function roundTo(number: number, precision: number = 2) {
  const tmp = Math.pow(10, precision);
  return Math.round(number * tmp) / tmp;
}
