import { getFunctions, httpsCallable } from "firebase/functions";
import { getFirebaseApp } from "./app";
import { Timestamp } from "firebase/firestore";

const functions = getFunctions(getFirebaseApp(), "europe-west1");
const addConsultationFunc = httpsCallable(functions, "addConsultation");
const getConsultationsFunc = httpsCallable(functions, "getConsultations");

export const addConsultation = async (consultation: {
  date: Date;
  label: string;
  precision: string;
  commandId: string;
}) => {
  try {
    const result: any = await addConsultationFunc(consultation);
    const data = result.data;
    const sanitizedMessage = data.text;
    return sanitizedMessage;
  } catch (error: any) {
    const code = error.code;
    const message = error.message;
    const details = error.details;
    console.error(code, message, details);
  }
};
const convertFirebaseTimestampToDate = (dt:any) => {
  if (dt && !dt.toDate) {
    dt = new Timestamp(dt._seconds, dt._nanoseconds);
  }
  if (dt && dt.toDate) {
    dt = dt.toDate();
  }
  return dt;
} 
export const listConsultations = async () => {
  try {
    const result: any = await getConsultationsFunc();
    if (result && result.data) {
      return result.data.map((c: any) => {
        convertFirebaseTimestampToDate((c as any).date);
        convertFirebaseTimestampToDate((c as any).createdAt);
        return c;
      });
    }
    return result;
  } catch (error: any) {
    const code = error.code;
    const message = error.message;
    const details = error.details;
    console.error(code, message, details);
  }
};
