/* eslint-disable jsx-a11y/anchor-is-valid */
import { Cart, cartChangedListener, openCart, readCart, showCartListener } from "business/payment/service/CartService";
import { useTopic } from "business/topic/service/TopicService";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Icon from "_common/component/element/Icon";
import { resolvePath, ROUTE_DETAIL } from "../App";
import Style from "./NavBar.module.css";
import SearchBar from "./SearhBar";

type NavBarProps = {
  menuId?: string;
  onOpenClose: (isOpen: boolean) => void;
  onlyLogo?: boolean;
};

const mediaQuery = window.matchMedia("(max-width: 768px)");

let _isMobile = mediaQuery.matches;
mediaQuery.addEventListener("change", () => {
  _isMobile = mediaQuery.matches;
});

const NavBar: React.FC<NavBarProps> = ({ menuId, onOpenClose, onlyLogo }) => {
  const [menu] = useTopic(menuId);
  const [isOpen, setIsOpen] = useState(false);
  const [cart, setCart] = useState<Cart | undefined>(readCart());

  useEffect(() => {
    return showCartListener(({ cart }) => {
      setCart(cart);
    });
  }, []);
  useEffect(() => {
    return cartChangedListener((cart) => {
      setCart(cart);
    });
  }, []);
  const handleBurgerClick = () => {
    setIsOpen((isOpen) => !isOpen);
  };
  useEffect(() => {
    onOpenClose(isOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handlePanierClick = () => {
    openCart();
  };

  const quantity = cart?.reduce((acc, item) => acc + item.quantity, 0) || 0;

  if (onlyLogo) {
    return (
      <>
        <nav className={clsx("navbar", Style.NavBar)} role="navigation" aria-label="main navigation">
          <div className={clsx("navbar-brand", Style.NavBarBrand)}>
            <Link to="/">
              <div className={Style.Bearer}>
                <span>Rouge Graphique</span>
                {!_isMobile && <span>É D I T E U R</span>}
              </div>
            </Link>
          </div>
        </nav>
      </>
    );
  }
  return (
    <>
      <nav className={clsx("navbar", Style.NavBar)} role="navigation" aria-label="main navigation">
        <div className={clsx("navbar-brand", Style.NavBarBrand)}>
          <Link to="/">
            <div className={Style.Bearer}>
              <span>Rouge Graphique</span>
              {!_isMobile && <span>É D I T E U R</span>}
            </div>
          </Link>
          <button
            className={clsx("navbar-burger", Style.Burger)}
            aria-label="menu"
            aria-expanded="false"
            data-target="navMenu"
            onClick={handleBurgerClick}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </button>
        </div>

        <menu id="navMenu" className={clsx("navbar-menu", Style.Menu, isOpen && "is-active")}>
          <SearchBar />
          <ul className="mr-2">
            {menu &&
              menu.articles.map((a) => (
                <li key={a.id}>
                  <Link to={resolvePath(ROUTE_DETAIL, { id: a.id })}>{a.shortTitle || a.title}</Link>
                </li>
              ))}

            {cart && cart.length > 0 && (
              <button key={"panier"} onClick={handlePanierClick} className="button" style={{ marginTop: "0.5rem" }}>
                <Icon name="bag-shopping" className="mr-1" /> Mon panier ({quantity})
              </button>
            )}
          </ul>
        </menu>
      </nav>
    </>
  );
};
/*
<div>
       <SearchBar />
       </div> */
export default NavBar;
