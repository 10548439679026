import { Article, getAllProducts } from "business/article/service/ArticleService";
import clsx from "clsx";
import { useRef, useState } from "react";
import Style from "./SearchBar.module.css";

let articles: Article[];

const SearchBar = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [search, setSearch] = useState("");
  const [result, setResult] = useState<Article[]>([]);

  //const [articles] = useProducts();

  const searchArticles = async (search: string) => {
    if (!articles) {
      articles = await getAllProducts();
    }
    const result = articles.filter(article => {
      const s = search.toLowerCase();
      return (
        s && (article.title.toLowerCase().includes(s) || (article.summary && article.summary.toLowerCase().includes(s)))
      );
    });
    setResult(result);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const search = e.target.value;
    setSearch(search);
    searchArticles(search);
  };
  const handleSearchKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === "Enter") {
      searchArticles(search);
    }
  };
  const handleSearchClick = () => {
    //searchArticles(search);
    inputRef.current && (inputRef.current as any).focus();
  };
  return (
    <div className={clsx(Style.SearchBar, "navbar-item")}>
      <div className={clsx(Style.Field, "field has-addons")}>
        <div className='control'>
          <input
            className='input'
            type='search'
            value={search}
            onKeyUp={handleSearchKeyUp}
            onChange={handleSearchChange}
            placeholder='Rechercher...'
            aria-label='Rechercher'
            ref={inputRef}
          />
        </div>
        <div className='control'>
          <button type='button' className={clsx(Style.Button, "button")} title='Rechercher' onClick={handleSearchClick}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              stroke='#f0e2e5'
              style={{ width: 24, height: 24 }}
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
                d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z'
              ></path>
            </svg>
          </button>
        </div>

        <div className={clsx(Style.Result)}>
          {result &&
            result.map(article => {
              return (
                <div key={article.id} className={clsx(Style.ResultItem, "navbar-item")}>
                  <a href={`/${article.id}`}>{article.shortTitle || article.title}</a>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
