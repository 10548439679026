export function sessionStorageWrite(key, content) {
  const content2 = content.replace(/…/g, '###...###')
  sessionStorage.setItem(key, btoa(content2));
}

export function sessionStorageRead(key) {
  const content = atob(sessionStorage.getItem(key));
  return content.replace(/###...###/g, '…');
}

export function sessionStorageObjectWrite(key, content) {
  const content2 = JSON.stringify(content).replace(/…/g, '###...###')
  sessionStorage.setItem(key, btoa(content2));
}

export function sessionStorageObjectRead(key, defaultValue) {
  const data = sessionStorage.getItem(key);
  try {
    const content = atob(data);
    return JSON.parse(content.replace(/###...###/g, '…'));
  } catch (err) {
    return defaultValue;
  }
}

export function sessionStorageRemove(key) {
  sessionStorage.removeItem(key);
}
