import { useEffect, useState } from "react";
import Icon from "_common/component/element/Icon";
import { Cart, showCartListener } from "../service/CartService";
import Style from "./CartDetail.module.css";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import CartTable from "./CartTable";

const CartDetail = () => {
  const [visible, setVisible] = useState(false);
  const [cart, setCart] = useState<Cart>();
  const navigate = useNavigate();

  useEffect(() => {
    return showCartListener(({ show, cart }) => {
      setCart(cart);
      setVisible(show);
    });
  }, []);

  const handleClose = () => setVisible(false);

  const handleCartChanged = (cart: Cart) => {
    setCart(cart);
  };

  const handlePaiementBtnClick = () => {
    handleClose();
    navigate("/paiement");
   
  };
  const quantity = cart?.reduce((acc, item) => acc + item.quantity, 0) || 0;


  return (
    <>
      {visible && (
        <div className='modal is-active'>
          <div className='modal-background'></div>
          <div className={clsx("modal-card", Style.modalCard)}>
            <header className='modal-card-head'>
              <p className='modal-card-title'>
                <Icon name='bag-shopping' /> Mon panier ({quantity})
              </p>
              <button className='delete' aria-label='close' onClick={handleClose}></button>
            </header>
            <section className='modal-card-body'>
              <CartTable cart={cart} onCartChanged={handleCartChanged} showShippingCosts={false}/>
            </section>
            <footer className={clsx("modal-card-foot", Style.buttons)}>
              <button onClick={handleClose} className='button' aria-label='Continuer ma visite'>
                Continuer ma visite
              </button>
              <button disabled={!cart || cart.length === 0} className='button is-info' onClick={handlePaiementBtnClick}>
                Passer commande
              </button>
            </footer>
          </div>
        </div>
      )}
    </>
  );
};

export default CartDetail;
