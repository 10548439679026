import { Consultation } from "../service/ConsultationService"
import { fr } from "date-fns/locale";
import { addMonths, format } from "date-fns";
import { DayOfMonth, getDaysOfMonth } from "_common/service/DateUtil";
import { useState } from "react";

import clsx from "clsx";
import Style from "./ConsultCalendar.module.css";
const days = [...getDaysOfMonth(addMonths(new Date(), 4), fr), ...getDaysOfMonth(addMonths(new Date(), 5), fr), ].reduce((acc: DayOfMonth[], d: DayOfMonth) => {
    const day = format(d.date, "EEEE", { locale: fr });
    if (d.date < new Date()) {
        return acc;
    }
    if (day === "lundi") {
        d.precision = "14h30-16h"
        acc.push(d)
        acc.push({ ...d, precision: "20h00-21h30" })
    } else if (day === "mardi") {
        d.precision = "14h30-16h"
        acc.push(d)
    } else if (day === "dimanche") {
        d.precision = "14h30-16h"
        acc.push(d)
    }

    return acc;
}, [])

const ConsultCalendar = ({ onChange, consultations }: { onChange: (d: DayOfMonth) => void, consultations: (Consultation[] | undefined) }) => {

    const [selection, setSelection] = useState<DayOfMonth>();

    const handleSelect = (d: DayOfMonth) => {
        setSelection(d);
        onChange(d);
    }

    const availableDays = consultations ? days.filter(d => !consultations.find(c => `${c.label} - ${c.precision}` === `${d.label} - ${d.precision}`)) : [];

    const dayLabel = (label: string, precision: string) => `${label} - ${precision}`;
    return (<>
        <div style={{ fontSize: "1.4rem", marginTop: 30 }}>
            Merci de sélectionner la date de votre rendez-vous dans la liste ci-dessous :
        </div>

        <p className={Style.Container}>
            {availableDays.map((d, index) => {
                return <span onClick={() => handleSelect(d)} className={clsx(Style.item, d.enabled && Style.itemEnabeld, (selection === d) ? Style.itemSelected : "toto")} key={"dt-" + index}>{dayLabel(d.label, d.precision)}</span>
            })}
            {availableDays.length===0 && <span className={Style.Loading}>Chargement en cours...</span>}
        </p>
        {selection && <p className={Style.Summary}>
            Rendez-vous par téléphone le {dayLabel(selection?.label, selection?.precision)}
        </p>}
    </>)


}
export default ConsultCalendar;