import { useEffect, useState } from "react";
import { storageObjectRead, storageObjectWrite } from "_common/service/StorageService";
import ConfigConsentForm, { ConsentType } from "./ConfigConsentForm"

const UpdateConsentPage = ()=>{

    const [consent, setConsent] = useState({ stats: true, marketing: true });
    useEffect(() => {
        const consents = storageObjectRead("Consentements", null);
        if (consents) {
            setConsent(consents);
        }
      }, []);
    const handleConsentChanged= (consent:ConsentType)=> {
        storageObjectWrite("Consentements", consent);
        setConsent(consent);
    }

    return <div className="m1">
        <h1 className="title">Définissez ou modifiez vos préférences</h1>
        <br />
        <h2 className="subtitle">Nous utilisons des cookies pour optimiser notre site web et notre service.</h2>
        <ConfigConsentForm consent={consent} onConsentChanged={handleConsentChanged}/></div>

}

export default UpdateConsentPage