import Icon from "_common/component/element/Icon";
import { Cart, CartItem, saveCart } from "../service/CartService";
import { roundTo } from "_common/service/FunUtil";
import Style from "./CartTable.module.css";
import clsx from "clsx";

const mediaQuery = window.matchMedia("(max-width: 768px)");

let _isMobile = mediaQuery.matches;
mediaQuery.addEventListener("change", () => {
  _isMobile = mediaQuery.matches;
});
export type CartTableProps = {
  cart: Cart | undefined;
  onCartChanged: (cart: Cart) => void;
  showShippingCosts: boolean;
};

const CartTable: React.FC<CartTableProps> = ({ cart, showShippingCosts, onCartChanged }) => {
  const handleQuantityChange: (cartItem: CartItem, value: string) => void = (cartItem, value) => {
    cartItem.quantity = parseInt(value, 10);
    if (cartItem.quantity < 1) {
      cartItem.quantity = 1;
    }
    saveCart(cart!);
    onCartChanged([...cart!]);
  };

  const handleDeleteItem = (cartItem: CartItem) => {
    const newCart = [...(cart || [])];
    newCart.splice(newCart.indexOf(cartItem), 1);
    saveCart(newCart);
    onCartChanged(newCart);
  };

  const total = (cart && roundTo(cart.reduce((acc, item) => acc + roundTo(((showShippingCosts && item.applyShippingCosts) ? (item.shippingCosts || 0) : 0) + item.quantity * item.price), 0))) || 0;
  const shippingCosts = cart && cart.reduce && roundTo(cart.reduce((acc, item) => acc + roundTo(item.shippingCosts || 0), 0));

  return (
    <>
      {_isMobile &&
        cart &&
        cart.length > 0 &&
        cart.map((item, index) => {
          return (
            <div className='columns m1 box' key={index}>
              <div className={clsx("column", Style.cartItemCells)}>
                <img className={Style.cartItemImg} src={item.thumbnail} alt='' />
              </div>
              <div className={clsx("column", Style.cartItemCells)}>
                <label className='label'>Article</label>
                <span>{item.title}</span>
              </div>
              <div className={clsx("column", Style.cartItemCells)}>
                <label className='label'>Prix unitaire (TTC)</label>
                <span>{item.price} €</span>
              </div>
              {showShippingCosts && (shippingCosts || 0) > 0 && <div className={clsx("column", Style.cartItemCells)}>
                <label className='label'>Frais de port</label>
                <span>{((showShippingCosts && item.applyShippingCosts) ? (item.shippingCosts || 0) : 0)} €</span>
              </div>}
              <div className={clsx("column", Style.cartItemCells)}>
                <label className='label'>Quantité</label>
                <input
                  className='input'
                  title='quantité'
                  value={item.quantity}
                  onChange={({ target }) => handleQuantityChange(item, target.value)}
                  min='0'
                  max='99'
                  type='number'
                />
              </div>
              <div className={clsx("column", Style.cartItemCells)}>
                <label className='label'>Prix calculé (TTC)</label>
                <span>{roundTo(item.price * item.quantity)} €</span>
              </div>
              <div className={clsx("column", Style.cartItemCells)}>
                <Icon
                  tabIndex={0}
                  role='button'
                  aria-label={`Supprimer l'article ${item.title}`}
                  name='trash'
                  className={Style.cartItemDelIcon}
                  onClick={() => handleDeleteItem(item)}
                />{" "}
                <span aria-hidden='true'> Supprimer</span>
              </div>
            </div>
          );
        })}
      {!_isMobile && cart && cart.length > 0 && (
        <table className={clsx("table", Style.cartTable)}>
          <thead>
            <tr>
              <th colSpan={2} style={{ textAlign: "center" }}>
                Produit
              </th>
              <th>Prix unitaire</th>
              {showShippingCosts && (shippingCosts || 0) > 0 && <th>Frais de port</th>}
              <th>Quantité</th>
              <th>Total</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {cart &&
              cart.map((item, index) => {
                return (
                  <tr key={index}>
                    <td className={Style.cartItemCells}>
                      <img className={Style.cartItemImg} src={item.thumbnail} alt='' />
                    </td>
                    <td className={Style.cartItemCells}>
                      <span>{item.title}</span>
                    </td>
                    <td className={Style.cartItemCells}>
                      <span>{item.price}&nbsp;€</span>
                    </td>
                    {showShippingCosts && (shippingCosts || 0) > 0 && <td className={Style.cartItemCells}>
                      <span>{(item.applyShippingCosts ? (item.shippingCosts || 0) : 0)}&nbsp;€</span>
                    </td>}

                    <td className={Style.cartItemCells}>
                      <input
                        className='input'
                        title='quantité'
                        value={item.quantity}
                        onChange={({ target }) => handleQuantityChange(item, target.value)}
                        min='0'
                        max='99'
                        type='number'
                      />
                    </td>
                    <td className={Style.cartItemCells}>
                      <span>{roundTo(((showShippingCosts && item.applyShippingCosts) ? (item.shippingCosts || 0) : 0) + item.price * item.quantity)}&nbsp;€</span>
                    </td>
                    <td className={Style.cartItemCells}>
                      <Icon
                        tabIndex={0}
                        role='button'
                        aria-label={`Supprimer l'article ${item.title}`}
                        name='trash'
                        className={Style.cartItemDelIcon}
                        onClick={() => handleDeleteItem(item)}
                      />
                    </td>
                  </tr>
                );
              })}

            <tr key={"total"}>
              <td className={clsx(Style.cartItemCells, Style.cartTotalLabelCells)} colSpan={4}>
                <span>Total général</span>
              </td>

              <td className={Style.cartItemCells}>
                <span>{total}&nbsp;€</span>
              </td>
              <td className={Style.cartItemCells}></td>
            </tr>
          </tbody>
        </table>
      )}
      {cart && cart.length === 0 && <div className={Style.emptyCart}>Panier vide</div>}
    </>
  );
};

export default CartTable;
