import Style from "./ConfigConsentForm.module.css";
export type ConsentType = {
  stats: boolean;
  marketing: boolean;
};
export type ConfigConsentFormProps = {
  consent: ConsentType;
  onConsentChanged: (consent: ConsentType) => void;
};

const ConfigConsentForm: React.FC<ConfigConsentFormProps> = ({ consent, onConsentChanged }) => {
  
  const handleStatClick = () => {
    const newConsent = {...consent, stats: !consent.stats};
  
    onConsentChanged(newConsent);
  };
  const handleMarketingClick = () => {
    const newConsent = {...consent, marketing: !consent.marketing};
   
    onConsentChanged(newConsent);
  };

  return (
    <div className={Style.prefsContent}>
      <h3 className={Style.prefsContentTitle}>
        <input title='fonctionnel' className='checkbox' type='checkbox' defaultChecked={true} disabled={true}></input>{" "}
        Fonctionnel
      </h3>

      <p>
        Le stockage ou l’accès technique est strictement nécessaire dans la finalité d’intérêt légitime de permettre
        l’utilisation d’un service spécifique explicitement demandé par l’abonné ou l’utilisateur, ou dans le seul but
        d’effectuer la transmission d’une communication sur un réseau de communications électroniques.
      </p>

      <h3 className={Style.prefsContentTitle}>
        <input
          id='stats'
          checked={consent.stats}
          title='Statistiques'
          className='checkbox'
          type='checkbox'
          onClick={handleStatClick}
        ></input>{" "}
        <label htmlFor='stats'> Statistiques</label>
      </h3>

      <p>
        Le stockage ou l’accès technique qui est utilisé exclusivement dans des finalités statistiques anonymes. En
        l’absence d’une assignation à comparaître, d’une conformité volontaire de la part de votre fournisseur d’accès à
        internet ou d’enregistrements supplémentaires provenant d’une tierce partie, les informations stockées ou
        extraites à cette seule fin ne peuvent généralement pas être utilisées pour vous identifier.
      </p>
      <h3 className={Style.prefsContentTitle}>
        <input
          id='marketing'
          checked={consent.marketing}
          title='Marketing'
          type='checkbox'
          onClick={handleMarketingClick}
        ></input>{" "}
        <label htmlFor='marketing'> Marketing</label>
      </h3>

      <p>
        Le stockage ou l’accès technique est nécessaire pour créer des profils d’utilisateurs afin d’envoyer des
        publicités, ou pour suivre l’utilisateur sur un site web ou sur plusieurs sites web ayant des finalités
        marketing similaires. A noter que les Editions Rouge Graphique ne font pas usage de vos données à des fins marketing et n'ont pas l'intention de le faire à l'avenir.
      </p>
    </div>
  );
};
export default ConfigConsentForm;
