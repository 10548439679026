import { addDocument, updateDocument } from "_core/firebase/store";
const FIREBASE_PATH_COMMANDS = "commands";

export type CommandModel = {
  mail: string;
  phone: string;
  cart: string;
  totalEuro: number;
  nbArticles: number;
  createdAt: Date;
  updatedAt?: Date;
  comment: string;
  status: string;
  payer: string;
  payerID?: string | null | undefined;
  shipping?:string;
};

export type UpdateCommandModel = {
  updatedAt?: Date;
  status: string;
  payer: string;
  orderID: string;
  payerID?: string | null | undefined;
  orderCreateTime: string;
  orderUpdateTime: string;
  order?:string;
  shipping?:string;
  transactionId?:string;
};

export type UpdateErrorCommandModel = {
  updatedAt?: Date;
  status: string;
  error?:string;
};

export const createCommand = async (command: CommandModel) => {
  const res = await addDocument(FIREBASE_PATH_COMMANDS, command);
  return res;
};

export const saveCommand = async (commandId: string, command: UpdateCommandModel | UpdateErrorCommandModel) => {
  const res = await updateDocument(FIREBASE_PATH_COMMANDS, commandId, command);
  return res;
};
