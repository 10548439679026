import clsx from "clsx";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { storageObjectRead, storageObjectWrite } from "_common/service/StorageService";
import ConfigConsentForm, { ConsentType } from "./ConfigConsentForm";
import Style from "./ConsentBanner.module.css";

const ConsentBanner = () => {
  const [hasConsent, setHasConsent] = useState(true);
  const [consents, setConsents] = useState({ stats: true, marketing: true });
  const [showPref, setShowPref] = useState(false);

  useEffect(() => {
    const consents = storageObjectRead("Consentements", null);
    if (!consents) {
      setHasConsent(false);
    } else {
      setConsents(consents);
    }
  }, []);

  const saveAcceptAll = () => {
    storageObjectWrite("Consentements", { stats: true, marketing: true });
    setHasConsent(true);
  };
  const saveRejectAll = () => {
    storageObjectWrite("Consentements", { stats: false, marketing: false });
    setHasConsent(true);
  };

  const saveAcceptConfig = () => {
    storageObjectWrite("Consentements", consents);
    setHasConsent(true);
  };

  const showPreferences = () => {
    setShowPref(true);
  };

  const handleConsentChanged = (consents: ConsentType) => {
    setConsents(consents);
  };
  if (hasConsent) {
    return null;
  }

  return (
    <div className={Style.banner}>
      <head className={Style.head}>Nous utilisons des cookies pour optimiser notre site web et notre service.</head>
      {showPref && <ConfigConsentForm consent={consents} onConsentChanged={handleConsentChanged} />}
      {showPref && (
        <div className={Style.buttonBar}>
          <button className={clsx(Style.button, Style.buttonAccept)} onClick={saveAcceptConfig}>
            Valider
          </button>
        </div>
      )}
      {!showPref && (
        <div className={Style.buttonBar}>
          <button className={clsx(Style.button, Style.buttonAccept)} onClick={saveAcceptAll}>
            Accepter
          </button>
          <button className={clsx(Style.button, Style.buttonReject)} onClick={saveRejectAll}>
            Refuser
          </button>
          <button onClick={showPreferences} className={clsx(Style.button, Style.buttonConfig)}>
            Préférences
          </button>
        </div>
      )}

      <footer className={clsx(Style.footer)}>
        {!showPref && <Link to='/politique-de-confidentialite'>Politique de confidentialité</Link>}
      </footer>
    </div>
  );
};

export default ConsentBanner;
