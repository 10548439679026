import {
  collection,
  getDocs,
  doc,
  getDoc,
  Firestore,
  CollectionReference,
  setDoc,
  deleteDoc,
  query,
  QueryConstraint,
  initializeFirestore,
  CACHE_SIZE_UNLIMITED,
  addDoc,
  updateDoc,
} from "firebase/firestore";

import { useCallback, useEffect, useState } from "react";
import { getFirebaseApp } from "./app";

const db: Firestore = initializeFirestore(getFirebaseApp(), { cacheSizeBytes: CACHE_SIZE_UNLIMITED });

if (!(window as any).RGAPP) {
  (window as any).RGAPP = { cache: {} };
}
const cache = (window as any).RGAPP.cache;

export const getCollection = (path: string, ...pathSegments: string[]) => collection(db, path, ...pathSegments);

export const deleteDocument = (path: string, id: string) => deleteDoc(doc(db, path, id));

export const getDocsForCollection = async <T>(coll: string, ...queryConstraints: QueryConstraint[]) => {
  const snapshot = await getDocs<T>(query(getCollection(coll) as any as CollectionReference<T>, ...queryConstraints));
  return snapshot;
};

export const getDocuments = async <T>(path: string, ...queryConstraints: QueryConstraint[]) => {
  const key = path + ".." + JSON.stringify(queryConstraints);
  if (cache[key]) {
    return cache[key];
  }
  const items = await getDocsForCollection<T>(path, ...queryConstraints);
  const result = (items && items.docs.map((t, i) => ({ ...t.data(), id: t.id }))) || [];

  result.forEach((r) => {
    cache[path + "." + r.id] = r;
  });
  cache[key] = result;

  return result;
};

export const getDocumentById = async <T extends { id: string }>(coll: string, id: string) => {
  if (cache[coll + "." + id]) {
    return cache[coll + "." + id];
  }
  const snapshot = await getDoc(doc(db, coll, id));
  if (!snapshot.exists()) {
    return undefined;
  }
  const result = { ...snapshot.data(), id: snapshot.id } as T;

  cache[coll + "." + id] = result;
  return result;
};

export const addDocument = async <T>(path: string, data: T) => {
  return await addDoc(collection(db, path), data);
};
export const setDocument = async <T>(path: string, id: string, data: T) => {
  return await setDoc(doc(db, path, id), data);
};
export const updateDocument = async <T>(path: string, id: string, data: T) => {
  return await updateDoc(doc(db, path, id), data);
};
export const useCollection = <T extends { id: string }>(
  path: string,
  ...queryConstraints: QueryConstraint[]
): [T[], boolean, () => void] => {
  const [loading, setLoading] = useState<boolean>(true);
  const [items, setItems] = useState<T[]>([]);

  const queryConstraintsStr = JSON.stringify(queryConstraints);

  const load = useCallback(async () => {
    try {
      const items = await getDocuments<T>(path, ...queryConstraints);
      setItems(items);
    } finally {
      setLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path, queryConstraintsStr]);

  useEffect(() => {
    load();
  }, [load]);

  const refresh = useCallback(() => {
    load();
  }, [load]);

  return [items, loading, refresh];
};

export const useDocument = <T extends { id: string }>(
  path: string,
  id: string | undefined,
  dateFields?: string[],
): [T | undefined, boolean, () => void] => {
  const mapper = useCallback(
    async (item: any): Promise<T> => {
      const res = { ...item };
      dateFields &&
        dateFields.forEach((field) => {
          if ((res as any)[field] && (res as any)[field].toDate) {
            // convert firebase date to js date
            (res as any)[field] = (res as any)[field].toDate();
          }
        });
      return res;
    },
    [dateFields],
  );
  return useMappedDocument<any, T>(path, id, mapper);
};

export const useMappedDocument = <IN extends { id: string }, OUT>(
  path: string,
  id: string | undefined,
  mapper: (input: IN) => Promise<OUT>,
): [OUT | undefined, boolean, () => void] => {
  const [loading, setLoading] = useState<boolean>(true);
  const [item, setItem] = useState<OUT>();

  const load = useCallback(async () => {
    if (id) {
      const item = await getDocumentById<IN>(path, id!);
      setItem(item && (await mapper(item)));
      setLoading(false);
    }
  }, [path, id, mapper]);

  useEffect(() => {
    load();
  }, [load]);

  const refresh = useCallback(() => {
    load();
  }, [load]);
  return [item, loading, refresh];
};
