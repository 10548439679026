import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "business/home/Home";
import Page from "business/page/Page";
import NavBar from "./header/NavBar";
import { replaceAll } from "_common/service/FunUtil";
import { Dictionary } from "_common/type/utils";
import Footer from "./footer/Footer";
import { useState } from "react";
import CartDetail from "business/payment/component/CartDetail";
import PaymentPage from "business/payment/PaymentPage";

import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { PP_CLIENT_ID } from "_core/config/paypal";
import ConsentBanner from "./consent/ConsentBanner";
import PageContact from "business/page/PageContact";
import UpdateConsentPage from "./consent/UpdateConsentPage";
import NotificationProvider from "_common/component/notification/NotificationProvider";

export const resolvePath = (path: string, variables: Dictionary) => {
  if (path.indexOf(":") > -1) {
    Object.keys(variables).forEach(key => {
      path = replaceAll(path, ":" + key, variables[key]);
    });
  }
  return path;
};
export const ROUTE_DETAIL = "/:id";
export const ROUTE_ARTICLE_DETAIL = "/article/:id";
export const ROUTE_PRODUCT_DETAIL = "/product/:id";
export const ROUTE_CONTACT = "/contact";
export const ROUTE_PAIEMENT = "/paiement";
export const ROUTE_UPDATE_CONSENT = "/consentements";

function App ({ home }: any) {
  const [isMainMenuOpen, setMainMenuIsOpen] = useState(false);
  const handleOpenClose = (isOpen: boolean) => {
    setMainMenuIsOpen(isOpen);
  };
  return (
    <div className='App'>
      <BrowserRouter>
        <PayPalScriptProvider options={{ "client-id": PP_CLIENT_ID, currency: "EUR" }}>
          <header role='banner'>
            <NavBar menuId={home?.headerMenu} onOpenClose={handleOpenClose} />
          </header>
          <main role='main' className='App-content' style={{ marginTop: isMainMenuOpen ? 89 : 0 }}>
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path={ROUTE_CONTACT} element={<PageContact />} />
              <Route path={ROUTE_PRODUCT_DETAIL} element={<Page />} />
              <Route path={ROUTE_ARTICLE_DETAIL} element={<Page />} />
              <Route path={ROUTE_DETAIL} element={<Page />} />
              <Route path={ROUTE_UPDATE_CONSENT} element={<UpdateConsentPage />} />
              <Route path={ROUTE_PAIEMENT} element={<PaymentPage />} />
            </Routes>
          </main>
          <Footer footerId={home?.footerTopic} />
          <CartDetail />
        </PayPalScriptProvider>

        <ConsentBanner />
        <NotificationProvider />
      </BrowserRouter>
    </div>
  );
}

export default App;
