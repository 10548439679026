import { getDocumentById } from "_core/firebase/store";
import { ThemeInfo } from "business/theme/service/ThemeService";
import { documentId, limit, orderBy, where } from "firebase/firestore";
import { getDocuments, useCollection, useDocument } from "_core/firebase/store";
import { CSSProperties } from "react";
import { DayOfMonth } from "_common/service/DateUtil";

export type MiscField = { name: string; label: string; value: string };

export type ArticleInfo = {
  id: string;
  title: string;
  shortTitle: string;
  articleType: ArticleType;
};
export type ArticleType = "article" | "product";
export type Article = {
  id: string;
  title: string;
  shortTitle?: string;
  summary?: string;
  content?: string;
  contents?: string[];
  contentEditor?: string;
  authors?: string;
  topics?: string[];
  tags?: string[];
  createdAt?: Date;
  updatedAt?: Date;
  thumbnail?: string;
  thumbnailAlt?: string;
  enabled?: boolean;
  themes?: ThemeInfo[];

  articleType: ArticleType;

  // specific product fields
  sku?: string;
  price?: number;
  shippingCosts?: number;
  stock?: number;
  downloadUrl?: string;

  // user custim fields (ex: for a book product a custom field could be the ISBN Code)
  misc: MiscField[];

  pageStyle?: Partial<CSSProperties>;
  style?: Partial<CSSProperties>;

  customScript?: string;
  customCss?: string;

  layout?:
    | "none"
    | "col-2"
    | "col-3"
    | "flex"
    | "col-left-center"
    | "center-col-right"
    | "col-left-center-col-right"
    | "head-col-left-center-footer"
    | "head-center-col-right-footer"
    | "head-col-left-center-col-right-footer"
    | "head-col-2-footer"
    | "head-col-3-footer";

  pluggins?: string[];
  themeAstral? : boolean;
};


export type ArticleThemeAstral = Article & {
  firstName:string;
  birthDate:string;
  birthHour:string;
  birthCity:string;
  birthCountry:string;
  consultation:DayOfMonth;
  mail:string;
  phone:string;
}

const FIREBASE_PATH_ARTICLES = "admin/publications/articles";
const DATE_FIELDS = ["createdAt", "updatedAt"];
const mapDates = async (
  items: Promise<
    (Article & {
      id: string;
    })[]
  >,
) => {
  return items.then((items) =>
    items.map((item) => {
      return {
        ...item,
        createdAt: item.createdAt && (item.createdAt as any).toDate && (item.createdAt as any).toDate(),
      };
    }),
  );
};
const whereIsArticle = where("articleType", "==", "article");
const whereIsProduct = where("articleType", "==", "product");

export const useArticles = () => useCollection<Article>(FIREBASE_PATH_ARTICLES, whereIsArticle);
export const useLastArticles = (max: number) =>
  useCollection<Article>(FIREBASE_PATH_ARTICLES, whereIsArticle, orderBy("createdAt", "desc"), limit(max));

export const getAllArticles = async () => mapDates(getDocuments<Article>(FIREBASE_PATH_ARTICLES, whereIsArticle));

export const useProducts = () => useCollection<Article>(FIREBASE_PATH_ARTICLES, whereIsProduct);
export const useLastProducts = (max: number) =>
  useCollection<Article>(FIREBASE_PATH_ARTICLES, whereIsProduct, orderBy("createdAt", "desc"), limit(max));

export const getAllProducts = async () => mapDates(getDocuments<Article>(FIREBASE_PATH_ARTICLES, whereIsProduct));

export const mapDbDataToModel = (data: Article | undefined) => {
  if (data) {
    if (!data.articleType) {
      data.articleType = "article";
    }
    if (!data.misc) {
      data.misc = [];
    }
    if (!data.tags) {
      data.tags = [];
    }
    if (!data.topics) {
      data.topics = [];
    }
    if (!data.themes) {
      data.themes = [];
    }
    if (!data.pluggins) {
      data.pluggins = [];
    }
    if (!data.price) {
      data.price = 0;
    }
    if (!data.shippingCosts) {
      data.shippingCosts = 0;
    }
    if (data.themeAstral && (data.themeAstral as any)=== "false") {
      data.themeAstral = false;
    }
    
    if (!data.pageStyle) {
      data.pageStyle = {
        padding: "2rem",
      };
    }
    if (!data.style) {
      data.style = {
        background: "transparent",
        width: "890px",
        boxShadow: "rgb(50 50 93 / 25%) 0px 13px 27px -5px, rgb(0 0 0 / 30%) 0px 8px 16px -8px",
      };
    }
  }
  return data;
};

export const getArticle = async (id: string): Promise<Article | undefined> => {
  const data = await getDocumentById<Article>(FIREBASE_PATH_ARTICLES, id);
  if (data) {
    DATE_FIELDS.forEach((field: string) => {
      if (data[field as keyof Article] && (data as any)[field].toDate) {
        // convert firebase date to js date
        (data as any)[field] = (data as any)[field].toDate();
      }
    });
  }

  return mapDbDataToModel(data);
};
export const useArticle = (id: string | undefined): [Article | undefined, boolean, () => void] => {
  const [data, loading, refresh] = useDocument<Article>(FIREBASE_PATH_ARTICLES, id, DATE_FIELDS);

  return [mapDbDataToModel(data), loading, refresh];
};

export const getArticlesFromIds = async (articleIds: string[]) => {
  return mapDates(getDocuments<Article>(FIREBASE_PATH_ARTICLES, where(documentId(), "in", articleIds)));
};
