export function storageWrite(key, content) {
  const content2 = content.replace(/…/g, '###...###')
  localStorage.setItem(key, btoa(content2));
}

export function storageRead(key) {
  const content = atob(localStorage.getItem(key));
  return content.replace(/###...###/g, '…');
}

export function storageObjectWrite(key, content) {
  const content2 = JSON.stringify(content).replace(/…/g, '###...###')
  localStorage.setItem(key, btoa(content2));
}

export function storageObjectRead(key, defaultValue) {
  const data = localStorage.getItem(key);
  try {
    const content = atob(data);
    return JSON.parse(content.replace(/###...###/g, '…'));
  } catch (err) {
    return defaultValue;
  }
}

export function storageRemove(key) {
  localStorage.removeItem(key);
}
