import { Article, getArticle } from "business/article/service/ArticleService";
import { usePage } from "business/page/service/PageService";
import { useEffect, useState } from "react";
import RichTextComponent from "business/rich-content/RichTextComponent";
import { getPlugginsFromIds, Pluggin } from "business/pluggin/PlugginService";

type HomeProps = {};

const Home: React.FC<HomeProps> = () => {
  const [home] = usePage("home");

  const [blocs, setBlocs] = useState<{ article: Article; pluggins: Pluggin[] }[]>([]);

  useEffect(() => {
    if (home) {
      const blocs: { article: Article; pluggins: Pluggin[] }[] = [];
      home.articlBlocs.forEach(async (id) => {
        const article = await getArticle(id);
        if (article) {
          const pluggins =
            (article?.pluggins && article?.pluggins.length > 0 && (await getPlugginsFromIds(article?.pluggins))) || [];

          blocs.push({ article, pluggins });
          setBlocs([...blocs]);
        }
      });
    }
  }, [home]);

  return (
    <div data-page-id="home">
      {blocs &&
        blocs.map(({ article, pluggins }) => (
          <RichTextComponent
            contextKey="home"
            contentKey={article.id}
            contents={(article?.layout !== "none" && article?.contents) || undefined}
            key={article.id}
            data={article}
            customScript={article?.customScript}
            plugginScript={pluggins?.map((p) => p.script || "")}
            plugginCss={pluggins?.map((p) => p.css || "")}
          />
        ))}
    </div>
  );
};

export default Home;
