import { documentId, where } from "firebase/firestore";
import { getDocuments, useCollection, useDocument } from "_core/firebase/store";
export type PlugginInfo = {
  id: string;
  name: string;
};
export type Pluggin = PlugginInfo & {
  description: string;
  script?: string;
  css?: string;
  enabled: boolean;
  createdAt?: Date;
  updatedAt?: Date;
};
const mapDates = async (
  items: Promise<
    (Pluggin & {
      id: string;
    })[]
  >,
) => {
  return items.then((items) =>
    items.map((item) => {
      return { ...item, createdAt: item.createdAt && (item.createdAt as any).toDate && (item.createdAt as any).toDate() };
    }),
  );
};
const FIREBASE_PATH_PLUGGINS = "admin/publications/pluggins";
const DATE_FIELDS = ["createdAt", "updatedAt"];
export const getAllPluggins = async () => getDocuments<Pluggin>(FIREBASE_PATH_PLUGGINS);

export const usePluggins = () => useCollection<Pluggin>(FIREBASE_PATH_PLUGGINS);

export const usePluggin = (id: string | undefined) => useDocument<Pluggin>(FIREBASE_PATH_PLUGGINS, id, DATE_FIELDS);

export const getPlugginsFromIds = async (plugginIds: string[]) => {
  if (!plugginIds || plugginIds.length === 0) {
    return [];
  }
  return mapDates(getDocuments<Pluggin>(FIREBASE_PATH_PLUGGINS, where(documentId(), "in", plugginIds)));
};
