import { createRoot, hydrateRoot } from "react-dom/client";
import "./index.css";
import App from "_core/app/App";
import { getPage } from "business/page/service/PageService";
import ConsultationPaymentPage from "business/payment/ConsultationPaymentPage";

if(window.location.href.indexOf('/consultation-rendez-vous')>0) {
  const rootElement = document.getElementById("root");
  if (rootElement?.hasChildNodes()) {
    hydrateRoot(rootElement, <ConsultationPaymentPage />);
  } else {
    const root = createRoot(rootElement as any);
    root.render(<ConsultationPaymentPage />);
  }
} else {


  const main = async () => {




    const home = await getPage("home");
  
    const rootElement = document.getElementById("root");
    if (rootElement?.hasChildNodes()) {
      hydrateRoot(rootElement, <App home={home} />);
    } else {
      const root = createRoot(rootElement as any);
      root.render(<App home={home} />);
    }
  };
  
  main();
  
}
