import { useRef } from "react";

import Async from "_common/component/helper/Async";
import clsx from "clsx";
import Style from "./RichTextComponent.module.css";
import { ContentArray } from "./render/richTextRenderer";
import { useShallowEffect } from "_common/hook/useShallowEffect";
import { replaceAll } from "_common/service/FunUtil";
import { Article } from "business/article/service/ArticleService";
import { Dictionary } from "_common/type/utils";

const variablesMapping = (key: string) => {
  if (key === "PRICE") {
    return ["Prix", "PRIX", key];
  } else if (key === "SHIPPINGCOSTS") {
    return ["FRAISDEPORT", "FRAIS-DE-PORT", "FRAIS DE PORT", key];
  } else if (key === "TITLE") {
    return ["Titre", "TITRE", key];
  } else if (key === "AUTHORS") {
    return ["AUTEUR", "AUTEURS", key];
  } else if (key === "SUMMARY") {
    return ["RESUME", "DESCRIPTION COURTE", "SHORT DESCRIPTION", "DESCRIPTION", key];
  } else {
    return [key];
  }
};

const replaceVariables = (content: string, variables: Dictionary) => {
  let res = content;
  Object.keys(variables).forEach((key: string) => {
    const value = variables[key];
    variablesMapping(key.toUpperCase()).forEach((k: string) => {
      res = replaceAll(res, `{{${variablesMapping(k.toUpperCase())}}}`, value);
    });
  });

  return res;
};

const isEmptyContent = (c: string | undefined) => !c || (c === '[{"type":"p","children":[{"text":""}]}]');

type RichTextComponentProps = {
  data: Article;
  contextKey: string;
  contentKey: string;
  contents?: string[];
  customScript?: string;
  onHasTitle?: (hasTitle: boolean, title: string) => void;
  plugginScript?: string[];
  plugginCss?: string[];
  children?: React.ReactNode;
};
const RichTextComponent = ({
  data,
  contextKey,
  contentKey,
  contents,
  onHasTitle,
  customScript,
  plugginCss,
  plugginScript,
  children,
}: RichTextComponentProps) => {
  const { customCss, pageStyle, style } = data;
  const targetId = useRef("target" + contextKey + "_" + contentKey);
  const refComp = useRef<HTMLDivElement | null>(null);
  const renderContentBody = async (content: string | undefined) => {
    content = replaceVariables(content || "", data);

    const contentArray = content && JSON.parse(content);

    if (contentArray && contentArray.length && contentArray.length > 0) {
      return <ContentArray key={"cr"+ contextKey + "_" + contentKey} context={{index:0, contextKey, contentKey, page:{article:data}, name:"root"}}>{contentArray}</ContentArray>;
    }
  };

  const handleRendered = () => {
    if (refComp) {
      const h1 = refComp.current?.querySelector("h1");
      onHasTitle && onHasTitle(!!h1, h1?.innerText || "");
    }
  };

  useShallowEffect(() => {
    const scs: HTMLScriptElement[] = [];
    if (plugginScript && plugginScript.length > 0) {
      plugginScript.forEach(sc => {
        if (sc) {
          const scriptTag = document.createElement("script");
          scriptTag.type = "text/javascript";
          scriptTag.async = true;
          scriptTag.innerHTML = `(function(target, window){ ${sc} })( document.getElementById("${targetId.current}") , window)`;
          document.body.appendChild(scriptTag);
          scs.push(scriptTag);
        }
      });
    }
    if (customScript) {
      const scriptTag = document.createElement("script");
      scriptTag.type = "text/javascript";
      scriptTag.async = true;
      scriptTag.innerHTML = `(function(target, window){ ${customScript} })( document.getElementById("${targetId.current}"), window)`;
      document.body.appendChild(scriptTag);
      scs.push(scriptTag);
    }

    if (scs && scs.length > 0) {
      return () => {
        scs.forEach(sc => document.body.removeChild(sc));
      };
    }
  }, [customScript, plugginScript]);

  useShallowEffect(() => {
    const scs: HTMLStyleElement[] = [];
    if (plugginCss && plugginCss.length > 0) {
      plugginCss.forEach(sc => {
        if (sc) {
          const styleTag = document.createElement("style");
          styleTag.innerHTML = replaceAll(sc, "#target", "#" + targetId.current);
          document.body.appendChild(styleTag);
          scs.push(styleTag);
        }
      });
    }

    if (customCss) {
      const styleTag = document.createElement("style");
      styleTag.innerHTML = replaceAll(customCss, "#target", "#" + targetId.current);
      document.body.appendChild(styleTag);
      scs.push(styleTag);
    }

    if (scs && scs.length > 0) {
      return () => scs.forEach(sc => document.body.removeChild(sc));
    }
  }, [customCss, plugginCss]);
  
  return (
    <div id={targetId.current} ref={refComp}>
      <div
        data-context-key={contextKey}
        data-content-key={contentKey}
        className={clsx("RichEditor RichTextContainer", Style.RichTextContainer)}
        style={pageStyle || {}}
      >
        <div className={clsx("columns is-multiline", Style.RichText)} style={style || {}}>
          {!isEmptyContent(data.content) && (
            <div data-content-key={contentKey + "-0"} className='column is-full' style={{ padding: 10 }}>
              <Async onRendered={handleRendered} key='1'>
                {renderContentBody(data.content)}
              </Async>
            </div>
          )}
          {contents &&
            data.layout !== "flex" &&
            contents.map(
              (content, index) =>
                content && (
                  <div
                    data-content-key={contentKey + "-" + index + 1}
                    key={index}
                    className='column is-6'
                    style={{ padding: 0 }}
                  >
                    <Async>{renderContentBody(content)}</Async>
                  </div>
                ),
            )}

          {contents &&
            data.layout === "flex" &&
            contents.map((contentStr, index) => {
              const contentList: string[] = JSON.parse(contentStr);
              const len = contentList.length;
              const size = Math.ceil(12 / len);
              return (
                <div className={clsx("columns is-multiline", Style.RichText)} style={style || {}} key={index}>
                  {contentList.map((content, i) => {
                    return (
                      content && (
                        <div
                          data-content-key={contentKey + "-" + i + 1}
                          key={i}
                          className={"column is-" + size}
                         
                        >
                          <Async>{renderContentBody(JSON.stringify(content))}</Async>
                        </div>
                      )
                    );
                  })}
                </div>
              );
            })}
          {children}
        </div>
      </div>
    </div>
  );
};

export default RichTextComponent;
