export function isVisible(elem: any): boolean {
  if (!elem || !(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length)) {
    return false;
  }
  const st = window.getComputedStyle(elem);
  return st.display !== "none" && st.visibility !== "hidden";
}

export const copyTextToClipboard = async (text: string, onDone?: () => void) => {
  if ((window as any)["clipboardData"] && (window as any)["clipboardData"].setData) {
    (window as any)["clipboardData"].setData("Text", text);
    onDone && onDone();
  } else {
    navigator.clipboard.writeText(text).then(onDone && onDone);
  }
};

export function findParent(el: HTMLElement, predicat: (el: HTMLElement) => boolean): HTMLElement | null {
  if (el === document.body) {
    return null;
  }

  if (predicat(el)) {
    return el;
  }
  if (el.parentNode) {
    return findParent(el.parentNode as HTMLElement, predicat);
  }
  return null;
}

export const hasParent = (parent: HTMLElement) => (el: HTMLElement) => {
  return findParent(el, (el) => (el.parentNode as HTMLElement) === parent) !== null;
};

// source : https://stackoverflow.com/questions/152975/how-do-i-detect-a-click-outside-an-element
export function hideOnEscapeOrClickOutside(element: any, hideFunc: () => void, autoRemove: boolean = false) {
  const hideElement = () => {
    if (hideFunc) {
      hideFunc();
    } else {
      element.style.display = "none";
    }
    autoRemove && removeListeners();
  };

  const mouseListener = (event: MouseEvent) => {
    if (!isVisible(element)) {
      autoRemove && removeListeners();
    } else if (!element.contains(event.target)) {
      hideElement();
    }
  };

  const kbdListener = (event: KeyboardEvent) => {
    if (!isVisible(element)) {
      autoRemove && removeListeners();
    } else if (event.key === "Escape") {
      hideElement();
    }
  };

  const removeListeners = () => {
    document.removeEventListener("keydown", kbdListener);
    document.removeEventListener("click", mouseListener);
  };

  document.addEventListener("keydown", kbdListener);
  document.addEventListener("click", mouseListener);

  return removeListeners;
}

export function isExternUrl(url: string) {
  const hostname = window.location.hostname;
  let parser = document.createElement("a");
  parser.href = url;
  return hostname !== parser.hostname;
}
