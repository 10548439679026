import { addConsultation, listConsultations } from "_core/firebase/functions";

export type Consultation = {
  date:Date;
  label:string;
  precision:string;
  createdAt?: Date;
  updatedAt?: Date;
  comment?:string;

};

export const createConsultation = async (consultation: {
  date: Date;
  label: string;
  precision: string;
  commandId: string;
}) => {
  return await addConsultation(consultation);
}

export const getConsultations = async () => {
  const data = await listConsultations();
  return data;

}
