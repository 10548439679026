import { ArticleThemeAstral } from './../../article/service/ArticleService';
import { Article } from "business/article/service/ArticleService";
import EventEmitter from "_common/service/EventEmitter";
import { storageObjectRead, storageObjectWrite } from "_common/service/StorageService";
import { DayOfMonth } from '_common/service/DateUtil';

export type CartItem = {
  id: string;
  title: string;
  shortTitle?: string;
  thumbnail?: string;
  sku?: string;
  price: number;
  shippingCosts?: number;
  applyShippingCosts?: boolean;
  quantity: number;
  isPdf?: boolean;
};

export type CartItemThemeAstral = CartItem & {
  firstName:string;
  birthDate:string;
  birthHour:string;
  birthCity:string;
  birthCountry:string;
  consultation:DayOfMonth;
};
export type Cart = CartItem[];

const showCartEmitter = new EventEmitter<{ show: boolean; cart?: Cart }>();
const cartChangedEmitter = new EventEmitter<Cart>();

export const showCartListener = showCartEmitter.addListener;
export const cartChangedListener = cartChangedEmitter.addListener;

export const saveCart = (cart: Cart) => {
    storageObjectWrite("cart", cart);
    cartChangedEmitter.trigger(cart);
}

export const deleteCart = () => {
  storageObjectWrite("cart", []);
  cartChangedEmitter.trigger([]);
}

export const readCart = ()=> storageObjectRead("cart", []);

export const addToCart = (article: Article) => {
  // ajout de l'article dans le panier
  const panier: Cart = storageObjectRead("cart", []);
  const existingItem = panier.find((it) => it.id === article.id);
  if (!existingItem) {
    const cartItem = {
      id: article.id,
      title: article.title,
      shortTitle: article.shortTitle,
      thumbnail: article.thumbnail,
      sku: article.sku || "",
      price: article.price!,
      shippingCosts: article.shippingCosts,
      isPdf: !!(article.downloadUrl),
      quantity: 1,
    };

    if (article.themeAstral) {
      (cartItem as CartItemThemeAstral).birthCity = (article as ArticleThemeAstral).birthCity;
      (cartItem as CartItemThemeAstral).birthCountry = (article as ArticleThemeAstral).birthCountry;
      (cartItem as CartItemThemeAstral).birthDate = (article as ArticleThemeAstral).birthDate;
      (cartItem as CartItemThemeAstral).birthHour = (article as ArticleThemeAstral).birthHour;
      (cartItem as CartItemThemeAstral).firstName = (article as ArticleThemeAstral).firstName;
      (cartItem as CartItemThemeAstral).consultation = (article as ArticleThemeAstral).consultation;
    }

    panier.push(cartItem);
  }

  storageObjectWrite("cart", panier);
  cartChangedEmitter.trigger(panier);

  // affichage du paniers
  showCartEmitter.trigger({ show: true, cart: panier });
};

export const openCart = () => {
  const panier: Cart = storageObjectRead("cart", []);
  showCartEmitter.trigger({ show: true, cart: panier });
}
