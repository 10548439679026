import { ArticleInfo } from "business/article/service/ArticleService";
import {
  deleteDocument,
  getDocumentById,
  getDocuments,
  setDocument,
  useCollection,
  useDocument,
} from "_core/firebase/store";

export type Topic = {
  id: string;
  name: string;
  articles: ArticleInfo[];
  createdAt?: Date;
  updatedAt?: Date;
};

const DATE_FIELDS = ["createdAt", "updatedAt"];

const FIREBASE_PATH_TOPICS = "admin/publications/topics";
const mapDates = async (
  items: Promise<
    (Topic & {
      id: string;
    })[]
  >,
) => {
  return items.then((items) =>
    items.map((item) => {
      return {
        ...item,
        createdAt: item.createdAt && (item.createdAt as any).toDate && (item.createdAt as any).toDate(),
      };
    }),
  );
};

export const useTopics = () => useCollection<Topic>(FIREBASE_PATH_TOPICS);

export const createTopic = (id: string, name: string, articles: ArticleInfo[]) => {
  return setDocument(FIREBASE_PATH_TOPICS, id, { name, articles, createdAt: new Date() });
};
export const updateTopic = (topic: Topic) => {
  return setDocument(FIREBASE_PATH_TOPICS, topic.id, { ...topic, updatedAt: new Date() });
};
export const deleteTopic = async (id: string) => {
  return deleteDocument(FIREBASE_PATH_TOPICS, id);
};
export const getTopic = async (id: string): Promise<Topic | undefined> => {
  const data = await getDocumentById<Topic>(FIREBASE_PATH_TOPICS, id);
  if (data) {
    DATE_FIELDS.forEach((field: string) => {
      if (data[field as keyof Topic] && (data as any)[field].toDate) {
        // convert firebase date to js date
        (data as any)[field] = (data as any)[field].toDate();
      }
    });
  }

  return data;
};
export const useTopic = (id: string | undefined) => useDocument<Topic>(FIREBASE_PATH_TOPICS, id, DATE_FIELDS);
export const getAllTopics = async () => mapDates(getDocuments<Topic>(FIREBASE_PATH_TOPICS));
