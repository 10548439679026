import { orderBy } from "firebase/firestore";
import {
  deleteDocument,
  getDocumentById,
  getDocuments,
  setDocument,
  useCollection,
  useDocument,
} from "_core/firebase/store";
export type PageInfo = {
  id: string;
  name: string;
};
export type Page = PageInfo & {
  description: string;
  enabled: boolean;
  headerMenu?: string;
  articlBlocs: string[];
  footerTopic?: string;
  createdAt?: Date;
  updatedAt?: Date;
};

const FIREBASE_PATH_PAGES = "admin/publications/pages";
const DATE_FIELDS = ["createdAt", "updatedAt"];
export const FIREBASE_PATH_CONTACT = "contacts";

export const getAllPages = async () => getDocuments<Page>(FIREBASE_PATH_PAGES, orderBy("position"));

export const usePages = () => useCollection<Page>(FIREBASE_PATH_PAGES, orderBy("position"));

export const createPage = (page: Page) => {
  return setDocument(FIREBASE_PATH_PAGES, page.id, {
    name: page.name,
    description: page.description,
    enabled: page.enabled,
    headerMenu: page.headerMenu || "",
    articlBlocs: page.articlBlocs || [],
    footerTopic: page.footerTopic || "",
    createdAt: new Date(),
  });
};

export const updatePage = (page: Page) => {
  return setDocument(FIREBASE_PATH_PAGES, page.id, {
    name: page.name,
    description: page.description,
    enabled: page.enabled,
    headerMenu: page.headerMenu || "",
    articlBlocs: page.articlBlocs || [],
    footerTopic: page.footerTopic || "",
    updatedAt: new Date(),
  });
};

export const deletePage = async (id: string) => {
  return deleteDocument(FIREBASE_PATH_PAGES, id);
};

export const getPage = async (id: string): Promise<Page | undefined> => {
  const data = await getDocumentById<Page>(FIREBASE_PATH_PAGES, id);
  if (data) {
    DATE_FIELDS.forEach((field: string) => {
      if (data[field as keyof Page] && (data as any)[field].toDate) {
        // convert firebase date to js date
        (data as any)[field] = (data as any)[field].toDate();
      }
    });
  }

  return data;
};

export const usePage = (id: string | undefined) => useDocument<Page>(FIREBASE_PATH_PAGES, id, DATE_FIELDS);

export const saveContact = async ({ mail, subject, message }:any) => {
  try {
    const createdAt =  new Date();
    return setDocument(FIREBASE_PATH_CONTACT, mail+" "+createdAt.getTime(), {
      mail: mail || "",
      message: message || "",
      subject: subject || "",
      createDate:createdAt,
    });
  } catch (err) {
    console.error(err);
  }
};
