//import { PayPalButtons } from "@paypal/react-paypal-js";
import { useArticle } from "business/article/service/ArticleService";
import { getPlugginsFromIds, Pluggin } from "business/pluggin/PlugginService";
import RichTextComponent from "business/rich-content/RichTextComponent";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const Page = () => {
  const { productId, articleId, id } = useParams();
 

  const [article, loading] = useArticle(productId || articleId || id);
  const [pluggins, setPluggins] = useState<Pluggin[] | undefined>();

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  useEffect(() => {
    const load = async () => {
      const pluggins =
        (article?.pluggins && article?.pluggins.length > 0 && (await getPlugginsFromIds(article?.pluggins))) || [];
      setPluggins(pluggins);
    };

    load();
  }, [article]);

  if (loading || pluggins === undefined) {
    return <div></div>;
  }

  const handleHasTitle = (flag: boolean, title: string) => {
   
    if (flag) {
      document.title = `${title} - ROUGE GRAPHIQUE`;
    } else {
      document.title = `${article?.title} - ROUGE GRAPHIQUE`;
    }
  };

  return (
    <div data-page-id={article?.id || ""} key={article?.id || ""}>
    
      {article && (
        <RichTextComponent
          contextKey='page'
          contentKey={article?.id || ""}
          data={article}
          contents={(article?.layout !== "none" && article?.contents) || undefined}
          onHasTitle={handleHasTitle}
          customScript={article?.customScript}
          plugginScript={pluggins?.map(p => p.script || "")}
          plugginCss={pluggins?.map(p => p.css || "")}
        />
      )}

     
    </div>
  );
};

/*
SAMPLE PAYPAL BUTTON
 <PayPalButtons
        createOrder={(data, actions) => {
          return actions.order.create({
 
            purchase_units: [
              {
                description: "Desc ROUGE GRAPHIQUE",
                soft_descriptor:"Soft Descriptor",
                custom_id:"CMD-12345",
                amount: {
                  currency_code: "EUR",
                  value: "0.5",
                  breakdown: {
                    item_total: { currency_code: "EUR", value: "0.5" },
                    shipping: { currency_code: "EUR", value: "0" },
                    tax_total: { currency_code: "EUR", value: "0" },
                  },
                },
                items: [
                  {
                    name: "Article",
                    quantity: "1",
                    unit_amount: {
                      currency_code: "EUR",
                      value: "0.5",
                    },
                    description: "Ceci est un article",
                    sku:"SKU de l'article"
                  }
                ]
              },
            ],
          });
        }}
        onApprove={async (data, actions) => {
          return actions?.order?.capture().then(details => {
            const name = details?.payer?.name?.given_name;
            alert(`Transaction completed by ${name}`);
          });
        }}
      />

*/

export default Page;
