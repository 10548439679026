import React, { useState, useEffect } from "react";
import NotificationService, { NotificationConfig } from "./NotificationService";
import Notification from "./Notification";
import Style from "./NotificationProvider.module.css";

type NotificationProviderProps = {
  // add props here
};

const NotificationProvider: React.FC<NotificationProviderProps> = () => {
  const [activeNotifications, setActiveNotifications] = useState<NotificationConfig[]>([]);
  const handleClose = (conf: NotificationConfig) => {
    setActiveNotifications((prevActiveNotifications) => {
      const index = prevActiveNotifications.indexOf(conf);
      prevActiveNotifications.splice(index, 1);
      return [...prevActiveNotifications];
    });
  };
  useEffect(() => {
    NotificationService.setNotificationProvider((conf: NotificationConfig) => {
      setActiveNotifications((prevActiveNotifications) => [...prevActiveNotifications, conf]);
      return () => handleClose(conf);
    });
  }, []);

  const notifications = activeNotifications.map((conf) => {
    return (
      <Notification
        key={conf.uid}
        onClose={() => handleClose(conf)}
        typeNotification={conf.typeNotification}
        duration={conf.duration}
      >
        {conf.message}
      </Notification>
    );
  });

  return <div className={Style.Provider}>{notifications}</div>;
};

export default NotificationProvider;
